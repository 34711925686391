import React, { FC } from 'react';

import s from './App.module.scss';
import { Home } from './Home/Home';
import DocumentContainer from '../containers/DocumentContainer/DocumentContainer';
import { Widget } from '@ca-actual-projects/bca-widget';
import DocumentModel from '../models/DocumentModel/DocumentModel';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Footer } from '../components/Footer';
import logo from 'assets/logo/logo-desktop.svg';

export const App: FC = () => {
  const { openPdf } = DocumentModel();
  const navigate = useNavigate();
  return (
    <>
      <div className={s.wrapper}>
        <main className={s.page}>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/form" element={<div />} />
          </Routes>
          <Widget
            logo={logo}
            ymId={98760321}
            formPathname="form"
            afterAuth={() => {
              navigate('/form');
            }}
            onLogoClick={() => {
              navigate('/');
            }}
            pdfOpen={openPdf}
            short
            theme="blue"
            backUrl="https://prod.onbank.online"
          />
          <Footer />
        </main>
      </div>
      <DocumentContainer />
    </>
  );
};
