import React, { FC } from 'react';

import styles from './DocStyles.module.scss';

import { DocumentParametersType } from './index';

const OfferDoc: FC<DocumentParametersType> = ({ origin, name }) => (
  <div className={styles.docStyleContainer}>
    <h1>Оферта</h1>
    <p>
      Сайт {origin} (именуемый далее - «{name}»), предоставляет собой набор услуг, при
      помощи которых вам будет подобрано уникальное предложение по кредитному продукту.{' '}
      {name} - это информационный портал, на котором имеется возможность подать заявку и
      узнать предварительное решение сразу от нескольких партнеров, после чего останется
      выбрать подходящее предложение и получить его.
    </p>
    <p>
      Все услуги дистанционные, через сеть Интернет, круглосуточно, без выходных и
      праздничных дней. Эта возможность предоставляется Вам благодаря тому, что мы следим
      за работой нашего сервиса и стараемся наполнять его обновленными опциями, как можно
      чаще.
    </p>
    <p>
      Выдача кредитов и займов у партнеров осуществляется в российских рублях, на
      банковский счет, наличными или на карту, гражданам Российской Федерации. Сервис не
      относится к финансовым учреждениям, не является кредитной организацией или банком и
      не несет ответственность за заключенные впоследствии клиентами и партерами договора
      кредитования. Доступные суммы кредитов: до 5 000 000 рублей. Срок: до 60 месяцев,
      при процентной ставке: от 5,5%. При заполнении Анкеты на сайте
      <a href={origin} target="_blank" rel="noreferrer">
        <span>{origin}</span>
      </a>{' '}
    </p>
    <p>
      Ваша Заявка будет рассматриваться Партнером Оператора, с которыми у него заключено
      соглашение, содержащее условие об обработке персональных данных в объеме,
      необходимом для исполнения соглашения, а также условие о конфиденциальности и
      неразглашении информации, перечень которых размещен на сайте
      {origin} («
      <i>
        Перечень третьих лиц (Партнеров), которые осуществляют обработку персональных
        данных Клиентов на основании согласий Клиентов
      </i>
      »).
    </p>
  </div>
);

export default OfferDoc;
