import React, { useContext } from 'react';

import { MediaContext } from '../../context/MediaProvider';
import { Button } from '../../shared/Button';
import com from '../../styles/common.module.scss';

import { useCounter } from './hook/useCounter';
import s from './style/IssueMFO.module.scss';

export const IssueMfo: React.FC = () => {
  const tablet = useContext(MediaContext);
  const count = useCounter();
  const openModalHandler = () => {
    window.open(window.location.href);
    window.location.replace(`https://zaim-exp.ru/${window.location.search}`);
    localStorage.setItem('isReplace', 'true');
  };

  return (
    <div className={`${tablet ? com.container : ''}`}>
      <div className={s.issue}>
        <div className={s.issue__title} id="issue">
          <span>
            Сегодня выдано займов: <b>{count}</b>
          </span>
          <span>В течение 10 минут одобрим и Вам</span>
        </div>
        <Button className={s.button__money} onClick={openModalHandler}>
          Получить деньги
        </Button>
      </div>
    </div>
  );
};
