import React, { useContext } from 'react';

import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';

import { partnersData } from './data';
import s from './style/OurPartners.module.scss';

export const OurPartners: React.FC = () => {
  const tablet = useContext(MediaContext);

  return (
    <section className={`${tablet ? com.container : ''}`}>
      <div className={s.partners}>
        <h2 className={com.title__h2_landing}>Наши партнёры</h2>
        <div className={`${s.partners__items} ${!tablet && com.no_scroll_block}`}>
          {partnersData.map(logo => (
            <article key={logo} className={s.partners__item}>
              <img src={logo} alt="partners logo" />
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};
