import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { documentType, initialDocumentState } from './index';

export const documentSlice = createSlice({
  name: 'documentState',
  initialState: initialDocumentState,
  reducers: {
    openDoc(state, { payload }: PayloadAction<documentType>) {
      state.pdfFile = payload;
      state.opened = true;
    },
    closeDoc() {
      return { ...initialDocumentState };
    },
  },
});

export const { openDoc, closeDoc } = documentSlice.actions;

export default documentSlice.reducer;
