import React, { FC, useState } from 'react';

import arrow from '../../assets/other/arrow.svg';

import s from './style/Accordion.module.scss';
import { AccordionPropsType } from './type';

export const Accordion: FC<AccordionPropsType> = ({ title, textContent, children }) => {
  const [isActive, setIsActive] = useState(s.active);
  const [height, setHeight] = useState('10000px');
  const [rotate, setRotate] = useState('s.rotate');

  function toggleAccordion() {
    setIsActive(isActive === '' ? s.active : '');
    setHeight(isActive === s.active ? '0px' : `10000px`);
    setRotate(isActive === s.active ? '' : s.rotate);
  }

  return (
    <article className={s.accordion}>
      <button
        id="not-open-window"
        type="button"
        className={s.accordion__button}
        onClick={toggleAccordion}
      >
        <img src={arrow} className={`${s.accordion__icon} ${rotate}`} alt="arrow btn" />
        <p className={s.accordion__title} style={{ marginBottom: 0 }}>
          {title}
        </p>
      </button>
      {textContent}
      <div style={{ maxHeight: `${height}` }} className={s.accordion__content}>
        <div className={s.accordion__text}>{children}</div>
      </div>
    </article>
  );
};
