const urlConfig = {
  dengissite: 'https://dengis.site',
  zaiman: 'https://zaim-an.ru',
  migzaym: 'https://migzaym.com.ru',
  zaimactive: 'https://zaim-active.com.ru',
};

export type DomainsName = keyof typeof urlConfig;

export const getCurrentDomainName = (): DomainsName => {
  const { origin } = window.location;
  let name: DomainsName = 'zaiman';

  if (/localhost/i.test(origin)) {
    document.body.setAttribute('data-theme', name);
    return name;
  }

  for (const key in urlConfig) {
    const k: DomainsName = key as DomainsName;
    if (urlConfig[k].includes(origin)) {
      name = k;
    }
  }
  document.body.setAttribute('data-theme', name);
  return name;
};
