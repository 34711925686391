import clock from '../../../assets/icons/at-us/clock.svg';
import percent from '../../../assets/icons/at-us/percent.svg';
import search from '../../../assets/icons/at-us/search.svg';
import smile from '../../../assets/icons/at-us/smile.svg';

type AtUsDataTabType = {
  id: number;
  icon: string;
  title: string;
  subtitle: string;
  description: string;
};

export const atUsDataTab: AtUsDataTabType[] = [
  {
    id: 1,
    icon: search,
    title: 'Высокий процент\n одобрений',
    subtitle: 'Наш процент\n одобрений 96%',
    description:
      'Меньше чем за 1 минуту придет решение по вашей заявке.\n Система автоматически подберет тариф в соответствии с вашим запросом.',
  },
  {
    id: 2,
    icon: percent,
    title: 'Первый займ без\n процентов',
    subtitle: 'Ставка 0% на первый\n займ',
    description: 'При первом займе мы предлагаем ставку 0%',
  },
  {
    id: 3,
    icon: smile,
    title: 'Удобное\n погашение',
    subtitle: 'Все что вам нужно это\n мобильный телефон',
    description:
      'Все, что Вам понадобится - это телефон и номер паспорта. Вы можете получить заём у нас на сайте.',
  },
  {
    id: 4,
    icon: clock,
    title: 'Оформление за\n 5 минут',
    subtitle: 'Весь процесс\n оформления займа\n занимает не более 5-ти минут',
    description:
      'Сервис работает полностью онлайн, Вам не понадобится подписывать бумажные договора в офисе. Весь процесс оформления просходит онлайн и занимает не более 5-ти минут.',
  },
];
