import React, { FC } from 'react';

import { Advantages } from '../../components/Advantages';
import { AtUs } from '../../components/AtUs';
import { Banner } from '../../components/Banner';
import { HowItWorks } from '../../components/HowItWorks';
import { IssueMfo } from '../../components/IssueMFO';
import { MFO } from '../../components/MFO';
import { OurPartners } from '../../components/OurPartners';
import { Question } from '../../components/Question';
import s from '../App.module.scss';

export const Home: FC = () => (
  <div className={s.layout}>
    <Banner />
    <Advantages />
    <MFO />
    <HowItWorks />
    <OurPartners />
    <AtUs />
    <IssueMfo />
    <Question />
  </div>
);
