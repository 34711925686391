import React, { FC } from 'react';

import styles from './DocStyles.module.scss';

import { DocumentParametersType } from './index';

const ProcessingDoc: FC<DocumentParametersType> = ({ origin, mail, owner, ogrn }) => (
  <div className={styles.docStyleContainer}>
    <h1>Согласие на обработку персональных данных</h1>

    <h3>1. Общие положения</h3>
    <p>
      1.1 Физическое лицо (далее – Пользователь, Клиент), в результате проставления
      галочки напротив текста «Я соглашаюсь на обработку моих персональных денных и с
      условиями оферты» и/или нажатия на кнопку «Оформить карту», «Войти» и отправки
      заполненной формы на сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>{' '}
      (далее — Сайт) или иным предусмотренным функционалом сайта способом, принимает
      настоящее Согласие на обработку персональных данных (далее — Согласие). Действуя
      свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность,
      физическое лицо дает конкретное, предметное, информированное, сознательное и
      однозначное свое согласие {owner} {owner?.includes('ИП ') ? 'ОГРНИП' : 'ОГРН'}{' '}
      {ogrn}, (далее — Оператор), на обработку своих персональных данных в соответствии со
      ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», а также
      пользователь уведомлен и дает свое согласие на обработку персональных данных, в том
      числе на их передачу третьим лицам, ООО «Фин Промоут Системс» (ОГРН 1236600060290)
      действующего по поручению Оператора в соответствии с ч. 3 ст. 6 Федерального закона
      от 27.07.2006 № 152-ФЗ «О персональных данных», со следующими условиями:
    </p>
    <p>1.2 Согласие дается для следующих целей:</p>
    <ul id="l1" className={styles.list}>
      <li data-list-text="-">
        <p>
          для идентификации пользователя, зарегистрированного на Сайте, подтверждения
          личности пользователя и предоставления пользователю доступа к услугам и
          продуктам Сайта, доступным в личном кабинете, с целью недопущения
          несанкционированного доступа к аккаунту пользователя третьими лицами;
        </p>
      </li>
      <li data-list-text="-">
        <p>
          для обработки заявки на получение микрозайма / кредита от Партнеров Оператора,
          получения кредитного отчета, сформированного на основании кредитной истории с
          целью формирования и анализа оценки кредитоспособности Клиента, как в формате
          суждения, так и в оцифрованном виде (скоринговый балл), в том числе и в
          агрегированном виде (оценка, основанная на нескольких информационных и
          аналитических источниках);
        </p>
      </li>
      <li data-list-text="-">
        <p>
          для своевременного информирования Клиента об оказываемых Оператором и Партнерами
          услугах, акциях и скидках, а также включения Клиента в базу потенциальных
          клиентов Партнеров, в том числе для предложения Клиенту услуг Партнеров,
          информационных и рекламных сообщений по телефону, в почтовых отправлениях, в
          sms-сообщениях или в тексте электронных писем/сообщений (push-уведомления);
        </p>
      </li>
      <li data-list-text="-">
        <p>
          для получения услуг от Партнеров, связанных с оперативным получением доступа к
          кредитным, банковским, страховым и иным продуктам Партнеров включая получение
          кредитов, займов/микрозаймов, кредитных карт, открытие счетов, привлечение
          квалифицированной консультационной помощи.
        </p>
      </li>
    </ul>
    <p className="s2">
      1.3 Для целей настоящего Согласия, под персональными данными понимается любая
      информация, прямо или косвенно относящаяся к Клиенту, как к субъекту персональных
      данных, переданная Клиентом лично на сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>
      <span>
        и в сети Интернет, в том числе с использованием интернет-сервисов vk.com, ok.ru, в
        сответствии со следующим перечнем: фамилия, имя, отчество; гражданство; пол; год,
        месяц, дата и место рождения; адрес фактического проживания и регистрации; номер и
        серия документа, удостоверяющего личность, сведения о дате выдачи указанного
        документа и выдавшем его органе; место работы; адрес работы; стаж; должность;
        общий ежемесячный доход; образование; семейное положение; количество детей; данные
        о наличии автомобиля, недвижимости; номера контактных телефонов (в том числе
        мобильного); адрес электронной почты; СНИЛС, ИНН; сведения из бюро кредитных
        историй (получаемые по поручению Клиента), электронные данные, включая, но не
        ограничиваясь о пользовательском устройстве (среди которых разрешение, версия и
        другие атрибуты, характеризуемые пользовательское устройство); данные,
        характеризующие аудиторные сегменты, параметры сессии, данные о времени посещения
        Сайта; идентификатор пользователя, хранимый в cookie-файлах; Cookie-файлы,
        IP-адрес; данные о доверенном лице, а также иная информация, дополнительно
        представленная Оператору Клиентом, которая необходима в указанных целях.
      </span>
    </p>

    <p>
      1.4 Обработка персональных данных может осуществляться с использованием средств
      автоматизации или без таких, а также путем смешанной обработки персональных данных,
      включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
      изменение), извлечение, анализ, использование, передачу (предоставление, доступ, в
      том числе передачу третьим лицам), а также их получение от третьих лиц,
      обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе
      в информационных системах Оператора, и совершение иных действий, предусмотренных
      Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».
    </p>
    <p>
      1.5 Настоящим Согласием Клиент подтверждает, что дает согласие на обработку
      Персональных данных, в том числе на передачу третьим лицам - Партнерам Оператора, с
      которыми у него заключено соглашение, содержащее условие об обработке персональных
      данных в объеме, необходимом для исполнения соглашения, а также условие о
      конфиденциальности и неразглашении информации, перечень которых размещен на сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>{' '}
      («
      <i>
        Перечень третьих лиц (Партнеров), которые осуществляют обработку персональных
        данных Клиентов на основании согласий Клиентов
      </i>
      ») (далее – Перечень третьих лиц/Партнеров). При этом Оператор вправе по
      собственному усмотрению изменять, дополнять Перечень Третьих лиц, с размещением
      информации по Третьим лицам на своем официальном сайте в сети Интернет. Клиент
      выражает согласие и разрешает Оператору как получать от указанных третьих лиц, так и
      передавать этим третьим лицам его персональные данные.
    </p>
    <p>
      1.6 Дополнительно настоящим Клиент дает согласие Оператору на получение от Партнера
      любой информации о финансовых операциях Клиента, например: одобрение выдачи
      кредита/микрозайма, размер одобренного кредита/микрозайма, исполнение обязательств
      Клиента по погашению кредита/микрозайма. При этом Оператор гарантирует обеспечение
      безопасности переданных персональных данных. Клиент предупрежден, что Оператор
      вправе передавать его персональные данные для указанных выше целей третьим лицам
      только при условии соблюдения ими требований законодательства Российской Федерации
      об обеспечении ими конфиденциальности персональных данных и безопасности
      персональных данных при их обработке.
    </p>
    <p>
      1.7 Настоящим Согласием Клиент подтверждает, что дает согласие Партнерам
      неоднократно обращаться в одно или несколько бюро кредитных историй для получения
      кредитного отчета Клиента и иной информации, предоставления информации о нем, о его
      обязательствах по договорам потребительского займа и иной информации в порядке,
      предусмотренном Федеральным законом от 30.12.2004 г. № 218-ФЗ «О кредитных историях»
      с целью рассмотрения возможности предоставления Клиенту потребительского кредитов
      или потребительских займов, оценки платежеспособности, а также получения
      маркетинговых предложений от Партнеров.
    </p>
    <p>
      1.8 Настоящее Согласие также определяет порядок и условия использования аналогов
      собственноручной подписи (АСП) в процессе использования Сайта в целях направления
      Клиентом Оператору согласия на обработку персональных данных, оферты, прохождения
      регистрации, а также в ходе обмена электронными документами между Клиентом,
      Оператором и Партнерами. Не может происходить использование АСП в ходе обмена
      электронными документами между Партнерами, использование АСП происходит только между
      Клиентом и Оператором и/или между Клиентом и Партнером с посредничеством (участием)
      Оператора.
    </p>
    <p>
      1.9 АСП в целях настоящего Согласия является Код подтверждения, используемый
      Клиентом для подписания электронных документов, который представляет собой
      уникальный цифровой код, направляемый Оператором или Партнером Клиенту в
      СМС-сообщении на указанный Клиентом номер мобильного телефона. Определение лица,
      подписавшего электронный документ Кодом подтверждения, производится на основе
      сведений, указанных Клиентом при заключении настоящего Согласия, с учетом
      примененного Кода подтверждения. Руководствуясь положениями ч. 2 ст. 160
      Гражданского кодекса РФ и ч. 2 ст. 6 ФЗ «Об электронной подписи», Стороны
      договорились о том, что электронные документы, направляемые Оператору/Партнеру, и
      подписанные Кодом подтверждения, признаются электронными документами, равнозначными
      документам на бумажном носителе, подписанным собственноручной подписью.
    </p>
    <p>
      1.10 Электронный документ считается подписанным АСП Клиента, если он соответствует
      совокупности следующих требований:
    </p>
    <ul className={styles.list}>
      <li data-list-text="-">
        <p>
          электронный документ создан и (или) отправлен с использованием Сайта Оператора;
        </p>
      </li>
      <li data-list-text="-">
        <p>
          в текст электронного документа включен Код подтверждения, введенный Клиентом в
          специальное интерактивное поле на Сайте Оператора.
        </p>
      </li>
    </ul>
    <p>
      1.11 Факт подписания электронного документа Клиентом устанавливается путем
      сопоставления следующих сведений:
    </p>
    <ul className={styles.list}>
      <li data-list-text="-">
        <p>кода подтверждения, использованного для подписания электронного документа;</p>
      </li>
      <li data-list-text="-">
        <p>
          информации о предоставлении Кода подтверждения определенному Клиенту, хранящейся
          в Информационной системе Оператора;
        </p>
      </li>
      <li data-list-text="-">
        <p>
          технических данных об активности Клиента в ходе использования Сайта,
          автоматически зафиксированных в электронных журналах системы Оператора.
        </p>
      </li>
    </ul>
    <p>
      1.12 Оператор осуществляет хранение электронных документов, которые были созданы,
      переданы или приняты Сторонами в процессе использования системы Оператора. Кроме
      того, являющаяся частью системы Оператора автоматическая система протоколирования
      (создания логов) активности Клиента позволяет Сторонам достоверно определить, каким
      Клиентом и в какое время был сформирован, подписан или отправлен тот или иной
      электронный документ.
    </p>
    <p>
      1.13 Стороны соглашаются, что указанный в настоящем Согласии способ определения
      Клиента, создавшего и подписавшего электронный документ, является достаточным для
      цели достоверной аутентификации Клиента и исполнения настоящего Согласия.
    </p>
    <p>
      1.14 При этом Клиент обязан соблюдать конфиденциальность Кода подтверждения. Клиент
      несет риски, связанные с негативными последствиями недобросовестных действий третьих
      лиц, получивших доступ к конфиденциальной информации, которые могут наступить в
      связи с несоблюдением им обязанности соблюдать конфиденциальность.
    </p>
    <p>
      1.15 Настоящее согласие действует с момента его получения Оператором посредством
      регистрации Клиента на сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>{' '}
      , в момент подписания Согласия Аналогом собственноручной подписи и до отзыва
      согласия на обработку персональных данных. В случае, если в течение указанного срока
      Клиентом будет заключен договор с Партнером Оператора, Согласие Клиента сохраняет
      силу в течение всего срока действия каждого договора, а также в течение 3 (трех) лет
      с даты прекращения обязательств сторон по договорам. Согласие может быть отозвано
      Клиентом посредством направления письменного заявления в адрес Оператора по адресу
      электронной почты:{' '}
      <a href={`mailto:${mail}`} className="s3" target="_blank" rel="noreferrer">
        <span>{mail}</span>
      </a>
      .
    </p>
    <p>
      1.16 Настоящим Клиент признает и подтверждает, что с правами и обязанностями в
      соответствии с Федеральным законом «О персональных данных», в т.ч. порядком отзыва
      согласия на обработку персональных данных ознакомлен. Со своей стороны, Оператор
      гарантирует безопасность Клиентских данных. Если Клиент запрашивает ту или иную
      услугу через личный кабинет на сайте Оператора, значит, Клиент согласен оплатить ее,
      Клиент доверяет Оператору запрашивать у Партнеров любую информацию о нем, включая
      персональную. В случае изменения паспортных данных после регистрации на сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>
      , Клиенту требуется направить заявление на адрес электронной почты:
      <a href={`mailto:${mail}`} className="s3" target="_blank" rel="noreferrer">
        <span>{mail}</span>.
      </a>
    </p>
    <p>
      1.17 Оператор вправе в одностороннем порядке изменять (дополнять) условия настоящего
      Согласия. Новая редакция Согласия доводится до сведения Клиента посредством
      опубликования новой редакции соответствующего документа на Сайте{' '}
      <a href={origin} className="s3" target="_blank" rel="noreferrer">
        <span> {origin}</span>
      </a>
      .
    </p>

    <h3>
      2. Подписывая настоящее Согласие Клиент подписывает Согласие Партнеров по их форме,
      указаной ниже.
    </h3>

    <h4>
      2.1 <span>МТС Банк</span>
    </h4>

    <p className={styles.title}>
      Соглашение об использовании аналога собственноручной подписи (АСП) и обработке
      персональных данных ПАО «МТС-Банк»
    </p>

    <div className={styles.frame}>
      <p className={styles.title}>1. Термины</p>

      <p className={styles.title}>
        Клиент (пользователь) –{' '}
        <span>
          дееспособное физическое лицо, достигшее 18 - летнего возраста, оформляющее
          заявку на Продукт Банка.
        </span>
      </p>

      <p className={styles.title}>
        Банк –
        <span>
          Публичное акционерное общество «МТС-Банк» (115432, г. Москва, пр-т Андропова,
          д.18, корп. 1).
        </span>
      </p>

      <p className={styles.title}>
        Продукт –
        <span>
          услуги (продукты), предоставляемые Банком по заявке клиента/пользователя.
        </span>
      </p>

      <p className={styles.title}>
        АСП -
        <span>
          Код подтверждения, используемый для подписания электронных документов, который
          представляет собой уникальный цифровой код, направляемый Компанией2 и/или Банком
          в СМС-сообщении на указанный в экранных формах Клиентом номер мобильного
          телефона.
        </span>
      </p>

      <p className={styles.title}>
        Соглашение –
        <span>
          настоящее Соглашение об использовании аналога собственноручной подписи (АСП) и
          обработке персональных данных.
        </span>
      </p>

      <p className={styles.title}>
        Сайт -
        <span>
          экранные формы сайта {window.location.origin} (или мобильного приложения
          Компании2), содержащие заявку на Продукт Банка, реквизиты документа
          удостоверяющего личность Клиента, номер мобильного телефона и текст настоящего
          Соглашения.
        </span>
      </p>

      <p className={styles.title}>
        Компания2 –
        <span>
          Общество с Ограниченной Ответственностью «Фин Промоут Системс» (юр. адрес:
          620146, Свердловская область, город Екатеринбург, ул. Волгоградская, д. 18а).
        </span>
      </p>

      <p className={styles.title}>2. Предмет соглашения.</p>

      <p className={styles.text}>
        Настоящее Соглашение, является предложением (публичной офертой), адресованной
        Клиентам и определяет условия использования аналога собственноручной подписи
        (далее – АСП) в целях направления Клиентом Компании2 и Банку согласия на обработку
        его персональных данных, согласия на направление запроса в Бюро кредитных историй
        в целях рассмотрения возможности предоставления Клиенту продуктов Банка и иных
        согласий.
      </p>

      <p className={styles.text}>
        Акцептом предложения о заключении Соглашения в порядке, предусмотренном ч. 3 ст.
        438 ГК РФ, а также ч. 3 ст. 434 ГК РФ, является заполнение Клиентом экранных форм
        Сайта, а также совершение действий по направлению Компании2 и/или Банку
        сформированного электронного документа с указанием кода подтверждения (АСП).
      </p>

      <p className={styles.text}>
        Электронный документ считается подписанным АСП Клиента, если он был направлен с
        использованием Сайта и в текст направленного электронного документа включен код
        подтверждения, введённый Клиентом в интерактивное поле на Сайте, при этом факт
        подписания электронного документа Клиентом устанавливается путем сопоставления
        кода подтверждения, направленного Клиенту и информации о предоставлении кода
        подтверждения определённому Клиенту, хранящуюся в информационных системах
        Компании2 и/или Банка. Клиент, Компания2 и Банк обязаны соблюдать
        конфиденциальность кода АСП. Стороны соглашаются, что указанный в настоящем
        разделе способ определения Клиента, создавшего и подписавшего электронный
        документ, является достаточным для цели достоверной аутентификации Клиента и
        исполнения настоящего Соглашения.
      </p>

      <p className={styles.title}>3. Обработка персональных данных и иные согласия.</p>

      <p className={styles.text}>
        Клиент выражает Компании2 и Банку свое согласие на обработку, с использованием
        автоматизированных и/или неавтоматизированных способов обработки, следующих
        персональных данных:
      </p>

      <p className={styles.text}>
        фамилия, имя, отчество (в т.ч. прежние), год, месяц, дата и место рождения, пол,
        паспортные данные и иные данные документа, удостоверяющего личность, номер
        телефона (мобильный, стационарный, рабочий), адрес (регистрации, фактического
        проживания), адрес электронной почты, ИНН, СНИЛС, сведения о номере и серии
        страхового свидетельства государственного пенсионного страхования, семейное,
        социальное, имущественное положение, образование, профессия, сведения о занятости,
        доходах и расходах, сведения о кредитной истории, справки по форме 2-НДФЛ, 3-НДФЛ,
        а также иные сведения, необходимые в соответствии с действующим законодательством
        Российской Федерации в области персональных данных, с помощью которых можно
        идентифицировать субъекта персональных данных.
      </p>

      <p className={styles.text}>
        Настоящее согласие предоставляется для целей предоставления услуг/продуктов Банка,
        оценки платежеспособности Клиента.
      </p>

      <p className={styles.text}>
        Клиент уведомлен Компанией2 и соглашается с тем, что в указанных в настоящем
        разделе целях и на основании договора, заключенного между Компанией2 и Банком,
        персональные данные Клиента будут переданы Компанией2 Банку, и Компания2 поручает
        обработку персональных данных Банку.
      </p>

      <p className={styles.text}>
        Под обработкой Персональных данных понимается совершение Компанией2 и Банком
        операций с персональными данными, включая сбор, систематизацию, накопление,
        хранение, уточнение, использование, передачу (в т.ч. трансграничную),
        блокирование, уничтожение в т. ч. в информационных системах Компании2 и Банка.
      </p>

      <p className={styles.text}>
        Клиент осведомлен о праве отозвать данное согласие путем направления Компании2 и
        Банку уведомления в письменной форме. В случае отзыва настоящего согласия на
        обработку своих Персональных данных Компания2 и Банк обязаны прекратить обработку
        Персональных данных и уничтожить их в срок, не превышающий 30 календарных дней со
        дня получения такого заявления, кроме данных, необходимых для хранения в
        соответствии с действующим законодательством РФ. Настоящее согласие дается сроком
        на 5 (пять) лет и может быть отозвано досрочно, путем направления соответствующего
        письменного заявления Компании2 и Банку.
      </p>

      <p className={styles.text}>
        Клиент выражает согласие по вопросам, связанным с предоставляемыми Банком
        услугам/продуктам, в частности (но не ограничиваясь) Клиент выражает:
      </p>

      <p className={styles.text}>
        - согласие на запрос сведений в бюро кредитных историй, в этом случае
        предоставляется право в течение 6 (шести) месяцев получать в любом Бюро кредитных
        историй кредитные отчеты, сформированные на основании кредитной истории Клиента,
        для целей принятия решения о возможности предоставления Продуктов Банка, в том
        числе кредита (установления лимита кредитования)/ формирования персональных
        предложений от Банка;
      </p>

      <p className={styles.text}>
        - согласие на запрос сведений о размере и источниках его дохода в Пенсионном Фонде
        Российской Федерации;
      </p>

      <p className={styles.text}>
        - согласие на получение рекламы по сетям электросвязи от Банка;
      </p>
      <p className={styles.text}>
        - согласие на присоединение к многостороннему «Соглашению об использовании сервиса
        скоринга и обмена данными», размещённому по адресу:{' '}
        <a
          href="https://www.mtsbank.ru/upload/static/agreement_on_use_scoring_and_data_exchange.htm"
          target="_blank"
          rel="noreferrer"
        >
          https://www.mtsbank.ru/upload/static/agreement_on_use_scoring_and_data_exchange.htm
        </a>{' '}
        , в , в , в целях оценки Банком платежеспособности;
      </p>

      <p className={styles.text}>- волеизъявления по иным вопросам.</p>
    </div>

    <h4>
      2.2 <span>ТБанк</span>
    </h4>

    <p className={styles.title}>Согласие на обработку персональных данных</p>

    <div className={styles.frame}>
      <p>
        Я даю согласие ООО «Т-Партнерс», ОГРН 1217700548042, г. Москва, муниципальный
        округ Головинский вн.тер. г., Головинское ш., д. 5, к. 1, ЭТАЖ/ПОМ. 5/5017 (далее
        – Тинькофф.Партнеры); АО «ТБанк», ОГРН 1027739642281, универсальная лицензия Банка
        России № 2673, адрес 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26 (далее
        — Банк) на обработку всех моих персональных данных, имеющихся в распоряжении
        Банка/Т-Партнерс, предоставленных мной Банку/Т-Партнерс, в том числе указанных в
        заявке, и/или иным образом полученных Банком/Т-Партнерс, любыми способами, включая
        сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), извлечение, использование, передачу (предоставление, доступ),
        трансграничную передачу в страны согласно перечню, размещенному на сайте Банка{' '}
        <a href="https://www.tbank.ru/" target="_blank" rel="noreferrer">
          tbank.ru
        </a>{' '}
        , обезличивание, блокирование, удаление, уничтожение, а также на вышеуказанную
        обработку иных моих персональных данных, полученных в результате их обработки,
        включая обработку третьими лицами, с целью:
      </p>

      <p>• заключения с Банком универсального договора;</p>

      <p>• выпуска, обслуживания банковских карт;</p>

      <p>• предоставления мне товаров, работ и услуг;</p>

      <p>• создания информационных систем персональных данных Банка;</p>

      <p>
        • страхования моей жизни/ здоровья/ имущества и иного страхования, осуществляемого
        при содействии Банка или в пользу Банка и/или в связи с заключением договора;
      </p>

      <p>
        • заключения и исполнения договоров, где я являюсь стороной либо
        выгодоприобретателем или поручителем, либо лицом, уполномоченным на исполнение
        договора;
      </p>

      <p>
        • предоставления информации третьим лицам, в том числе осуществляющим деятельность
        по возврату просроченной задолженности;
      </p>

      <p>
        • а также в любых других целях, прямо или косвенно связанных с выпуском и
        обслуживанием банковских карт и предложением иных продуктов Банка, и направления
        мне информации о новых продуктах и услугах Банка и/или его контрагентов.
      </p>

      <p>
        Я даю согласие контрагентам/партнерам Банка/Т-Партнерс на обработку всех моих
        персональных данных, имеющихся в распоряжении/доступе Банка/Т-Партнерс и/или
        контрагентов Банка/Т-Партнерс, в том числе с целью информирования меня об услугах
        контрагентов, а также на обработку сведений об абонентах и оказываемым им услугам
        связи (в случае если контрагентом Банка/Т-Партнерс является оператор связи) с
        целью проведения оценки вероятности моей платежеспособности в будущем для принятия
        решения о выпуске кредитной карты.
      </p>

      <p>
        Указанное согласие дано на срок 15 лет, а в случае его отзыва обработка моих
        персональных данных должна быть прекращена Банком/Т-Партнерс и/или третьими лицами
        и данные уничтожены при условии расторжения Договора и полного погашения
        задолженности по Договору в срок в соответствии с действующим законодательством
        Российской Федерации.
      </p>

      <p>
        Я даю согласие на получение кредитного отчета в порядке и на условиях,
        определенных Федеральным законом № 218-ФЗ от 30.12.2004 г., в любых бюро кредитных
        историй в целях проверки предоставленных мной сведений, оценки моей
        платежеспособности, формирования Банком предложений по кредитным и иным банковским
        продуктам, а также для принятия решения о заключении со мной договоров с Банком. Я
        подтверждаю и соглашаюсь с тем, что копия моего паспорта, в том числе
        предоставленная ранее, является неотъемлемой частью настоящего согласия.
      </p>

      <p>
        Я уведомлен, что предоставление указанных выше согласий является моим правом, а не
        обязанностью, данные согласия могут быть отозваны в порядке, предусмотренном
        законодательством Российской Федерации и, если применимо, Условиями комплексного
        банковского обслуживания.
      </p>
    </div>

    <h4>
      2.3 <span>Свой Банк</span>
    </h4>

    <p className={styles.title}>Согласие на обработку персональных данных</p>

    <div className={styles.frame}>
      <p>
        1. Я, ____(ФИО, паспорт гражданина РФ серия _____ № ___ выдан ______ (кем, когда),
        адрес регистрации) сознательно, свободно, своей волей и в своем интересе даю свое
        согласие АО «Свой Банк» (ИНН 6453031840, ОГРН 1026400001870, адрес места
        нахождения: 121096, г. Москва, вн. Тер. Г. Муниципальный округ Филевский парк, ул.
        Василисы Кожиной, д.1, офис №804.8, АО «НБКИ» (адрес места нахождения: 121069, г.
        Москва, Скатертный пер., д. 20, стр.1, ИНН 7703548386), ЗАО «ОКБ» (адрес места
        нахождения: 115184, г. Москва, вн.тер.г. муниципальный округ Замоскворечье,
        Шлюзовая наб, д. 4, ИНН 7710561081), ООО «БКИ СБ» (129090, г. Москва, Каланчевская
        ул., д. 16, стр. 1, ИНН 7813199667), ООО «Кредитное бюро Русский Стандарт»
        (105318, г. Москва, вн.тер.г. муниципальный округ Преображенское, Преображенская
        пл, д. 8, ИНН 7719562097), а также лицам, указанным в Приложении 1
        «Пользовательского соглашения» (
        <a href="https://svoi.ru/personal-data-policy/" target="_blank" rel="noreferrer">
          https://svoi.ru/personal-data-policy/
        </a>
        ) на обработку в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
        персональных данных» всех моих персональных данных, предоставленных мною
        самостоятельно при регистрации и заполнении Анкеты на официальном сайте АО «Свой
        Банк» (далее также – Банк) в информационно-коммуникационной сети «Интернет» ({' '}
        <a href="https://www.svoi.ru" target="_blank" rel="noreferrer">
          https://www.svoi.ru
        </a>{' '}
        ), по электронной почте или иным способом, а также полученных АО «Свой Банк» и
        указанными выше бюро кредитных историй от третьих лиц в соответствии с настоящим
        Согласием, в том числе: фамилия, имя, отчество; пол; дата и место рождения;
        паспортные данные; идентификационный номер налогоплательщика, страховой номер
        индивидуального лицевого счета; фотографии; место проживания (официальной
        регистрации), включая регион проживания; адрес фактического проживания; адрес
        доставки; номера мобильного телефона и дополнительные контактные номера телефонов;
        электронная почта; тип занятости, наименование организации, сфера деятельности,
        стаж на текущем месте работы, доходы, образование, сведения о состоянии здоровья;
        информация, содержащаяся в кредитной истории; файлы cookies; идентификатор
        устройства; ip-адрес; технические характеристики устройства, с которого
        осуществлен вход на сайт{' '}
        <a href="https://www.svoi.ru" target="_blank" rel="noreferrer">
          https://www.svoi.ru
        </a>{' '}
        и мобильное приложение, а также указанные ниже сведения, полученные от ПАО
        «МегаФон».
      </p>

      <p>
        Я даю согласие АО «Свой Банк» на передачу в адрес ПАО «МегаФон» (127006, г.
        Москва, Переулок Оружейный, дом 41, ИНН 7812014560) сведений об абоненте
        (абонентские номера, сведения об абонентском устройстве), а также выражаю свое
        согласие ПАО «МегаФон» на обработку сведений обо мне как об абоненте, включая, но
        не ограничиваясь: абонентские номера, сведения об абонентском устройстве, другие
        данные, позволяющие идентифицировать абонентское устройство, иные сведения об
        оказываемых услугах по договору об оказании услуг связи, за исключением сведений,
        составляющих тайну связи, а также на передачу Банку указанных сведений и
        результата обработки указанных сведений.
      </p>

      <p>
        Я даю согласие АО «Свой Банк» на передачу в адрес ООО МФК «Мани Мен» (121096, г.
        Москва, ул. Василисы Кожиной, д.1, офис Д13, ИНН 7704784072) следующих сведений:
        фамилия, имя, отчество; пол; дата и место рождения; паспортные данные;
        идентификационный номер налогоплательщика, страховой номер индивидуального
        лицевого счета; фотографии; место проживания (официальной регистрации), включая
        регион проживания; адрес фактического проживания; номера мобильного телефона и
        дополнительные контактные номера телефонов; электронная почта; тип занятости,
        наименование организации, сфера деятельности, стаж на текущем месте работы,
        доходы, образование; информация, содержащаяся в кредитной истории, а также даю
        согласие на передачу в АО «Свой Банк» от ООО МФК «Мани Мен» результата обработки
        указанных сведений.
      </p>

      <p>
        Блок включается при оформлении Клиентом заявления на дебетовую или кредитную
        карту:
      </p>

      <p>
        Для присоединения к Правилам программы лояльности Акционерного общества
        «Национальная система платежных карт» (далее – Правила) в качестве Клиента в
        соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О
        персональных данных» заявляю о согласии на обработку моих персональных данных АО
        «Свой Банк», Акционерным обществом «Национальная система платежных карт» (АО
        «НСПК»), местонахождение: 115184, Москва, ул. Большая Татарская, д.11, и третьими
        лицами в целях и на условиях, определенных Правилами (
        <a href="https://privetmir.ru/rules/" target="_blank" rel="noreferrer">
          https://privetmir.ru/rules/
        </a>
        ).).
      </p>

      <p>
        2. Настоящее Согласие на обработку моих персональных данных дается в целях
        рассмотрения АО «Свой Банк» возможности заключения со мной договора или исполнения
        заключенного договора (в том числе для обработки персональных данных с
        использованием оценочных (скоринговых) методик, предоставления персональных данных
        третьим лицам для их обработки с применением оценочных (скоринговых) методик) в
        рамках комплексного дистанционного банковского обслуживания и предоставления
        возможности получать через удаленные каналы банковские продукты и пользоваться
        предоставляемыми сервисами и услугами, а также в целях контроля за качеством
        оказываемых Банком услуг и обеспечения технической поддержки, продвижения
        продуктов/услуг Банка и/или его партнеров путем осуществления прямых контактов с
        помощью средств связи (по сетям электросвязи, в том числе посредством
        использования телефонной, факсимильной, подвижной радиотелефонной связи или
        направления SMS-сообщений и сообщений по электронной почте).
      </p>

      <p>
        3. Настоящее согласие действует до момента достижения целей, указанных в пункте 2
        настоящего Согласия, за исключением согласия на получение информации о моей
        кредитной истории (пункт 6), которое действует в течение шести месяцев со дня
        оформления.
      </p>

      <p>
        4. Согласие на обработку и использование моих персональных данных может быть
        досрочно отозвано путем направления заявления в АО «Свой Банк» в простой
        письменной форме. Отказаться от рассылок рекламного характера, а также отозвать
        согласие на обработку моих данных в целях, указанных в пункте 2 настоящего
        Согласия, я вправе, кликнув по специальной ссылке в направленном мне на адрес
        электронной почты письме, а также путем направления сообщения по электронной почте
        office@svoi.ru, либо позвонив по номеру телефона 8 (800) 101-03-03.
      </p>

      <p>
        5. Настоящим я даю АО «Свой Банк» и бюро кредитных историй, указанным в п.1
        настоящего Согласия, свое согласие на осуществление любого действия (операции) или
        совокупности действий (операций) с использованием средств автоматизации или без
        использования таких средств с моими персональными данными, включая сбор (включая
        получение данных из открытых источников, а также размещенных в
        информационно-коммуникационной сети «Интернет»), запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (предоставление, доступ), включая передачу третьим лицам
        (в том числе, лицам, чьи контактные данные были мною предоставлены, а также лицам,
        действующим на основании агентских договоров или иных договоров, заключенных ими с
        АО «Свой Банк» (в том числе, в случае неисполнения и/или ненадлежащего исполнения
        мною обязательств по Договору Займа с целью осуществления этими лицами действий,
        направленных на взыскание просроченной задолженности по договору, а также с целью
        передачи мне информации, связанной с исполнением договора займа), блокирование,
        уничтожение персональных данных, а также на трансграничную передачу данных.
      </p>

      <p>
        6. В целях, указанных в пункте 2 настоящего Согласия, я предоставляю АО «Свой
        Банк» право получать информацию о моей кредитной истории в Центральном каталоге
        кредитных историй и кредитный отчет в Бюро кредитных историй в соответствии с
        Федеральным законом от 30.12.2004 № 218-ФЗ «О кредитных историях» и иных
        организациях, осуществляющих, в соответствии действующим законодательством,
        формирование, обработку и хранение кредитных историй. Право выбора бюро кредитных
        историй предоставляется мной АО «Свой Банк» по его усмотрению и дополнительного
        согласования со мной не требует.
      </p>

      <p>
        7. Я согласен, что АО «Свой Банк» вправе проверить любую сообщаемую мной
        информацию, а предоставленные мной документы и копии, будут храниться в Банк.
      </p>

      <p>
        8. Я даю свое согласие на получение от АО «Свой Банк» информационных материалов и
        иной информации, связанной с исполнением обязательств по заключенному договору, по
        любым каналам связи, включая: SMS-оповещение, почтовое письмо, телеграмму,
        голосовое сообщение, сообщение по электронной почте, личное сообщение в
        мессенджере или социальной сети, push-уведомления через мобильное приложение или
        браузер. При этом для данных информационных рассылок я разрешаю Банку использовать
        любую контактную информацию, переданную мной Банку.
      </p>

      <p>
        9. Блок включается при оформлении Клиентом заявления на дебетовую или кредитную
        карту:
      </p>

      <p>
        Одновременно выражаю свое согласие на получение информации о программе лояльности
        Акционерного общества «Национальная система платежных карт» (далее – Программа
        лояльности), о присоединении Клиента к Правилам программы лояльности Акционерного
        общества «Национальная система платежных карт» (регистрации в Программе
        лояльности), регистрации карты платежной системы «Мир» в Программе лояльности,
        предоставлении Клиенту информации о Программе лояльности, акциях в рамках
        Программы лояльности (
        <a href="https://privetmir.ru/rules/" target="_blank" rel="noreferrer">
          https://privetmir.ru/rules/
        </a>
        ), рекламной и иной информации в том числе посредством использования сети
        Интернет, а также телефонной и подвижной радиотелефонной связи.
      </p>
    </div>

    <h4>
      2.4 <span>УралСиб Банк</span>
    </h4>

    <div className={styles.frame}>
      <p>
        Даю согласие Публичному акционерному обществу «БАНК УРАЛСИБ» (ул. Ефремова, 8,
        г.Москва, Россия 119048) (далее - Банк) на обработку моих персональных данных, в
        целях: рассмотрения возможности заключения кредитного договора/договора
        поручительства/договора комплексного банковского обслуживания/договора для
        дебетовой карты, включая соглашение о предоставлении пакета услуг (далее -
        Договор); в случае возникновения договорных отношений - исполнения кредитного
        договора/договора поручительства/договора комплексного банковского
        обслуживания/договора для дебетовой карты, включая соглашение о предоставлении
        пакета услуг; формирования данных о моей кредитной истории; предоставления мне
        сведений уведомительного, рекламного или маркетингового характера о продуктах
        (включая продукты, по которым имеется предварительное согласие Банка на их
        предоставление), предложениях и услугах Банка, в том числе, о новых банковских
        продуктах, услугах, проводимых акциях, мероприятиях, о статусе принятия Банком
        решения о предоставлении мне продукта и/или услуги, о предоставлении мне продукта
        Банка и иной существенной информации; осуществления телефонной связи со мной (в
        т.ч. направление SMS-сообщений, push-уведомлений, сообщений через мессенджеры),
        рассылки писем в мой адрес, информирования через банкомат, через Интернет-сайт
        Банка (в т.ч. с использованием системы дистанционного банковского обслуживания),
        информирования о задолженности перед Банком (в т.ч. о сумме, сроках погашения
        задолженности, ежемесячном платеже); взыскания просроченной/проблемной
        задолженности; предоставления мне сведений уведомительного, рекламного или иного
        маркетингового характера о не банковских продуктах юридических лиц, перечень
        которых размещен на сайте Банка{' '}
        <a href="https://uralsib.ru/s/pdn" target="_blank" rel="noreferrer">
          https://uralsib.ru/s/pdn
        </a>{' '}
        (далее – Компании), о новых не банковских продуктах, услугах, проводимых акциях,
        мероприятиях, о статусе принятия Компаниями решения о предоставлении мне не
        банковского продукта и/или услуги, о предоставлении мне не банковского продукта и
        иной существенной информации.
        <br />
        Даю согласие Обществу с ограниченной ответственностью Микрокредитной компании
        «УРАЛСИБ Финанс» (комната 1, этаж 9, ул. Ефремова, 8, г. Москва, 119048) (далее -
        Общество) на обработку (в том числе, автоматизированную) всех моих персональных
        данных, указанных в настоящей заявке, всеми способами и в порядке, указанными в
        настоящей заявке, в целях рассмотрения Обществом заявки и принятия решения о
        возможности заключения со мной договора потребительского займа, предоставления мне
        Банком и (или) Обществом сведений уведомительного, рекламного или маркетингового
        характера о продуктах, предложениях и услугах Общества, в том числе, о проводимых
        акциях, мероприятиях, о статусе принятия Обществом решения о предоставлении мне
        продукта и/или услуги, иной существенной информации; осуществления телефонной
        связи со мной (в т.ч. с использованием SMS-сообщений, push-уведомлений, сообщений
        через мессенджеры), рассылки писем в мой адрес.
        <br />
        Также даю свое согласие Банку/Компаниям/Обществу на получение рекламы (рекламных
        сообщений) по сетям электросвязи с соответствии с частью 1 статьи 18 Федерального
        закона от 13.03.2006 №38-ФЗ «О рекламе», настоящее согласие дано мною на получение
        рекламных сообщений посредством звонков, SMS-сообщений, писем на адрес моей
        электронной почты, push-сообщений, сообщений в мессенджерах и иных приложениях,
        передающих и принимающих информацию с использованием сетей электросвязи, на
        номер(-а) телефонов и/или адрес(-а) электронной почты.
        <br />
        Признаю и подтверждаю, что в случае необходимости Банк вправе в объеме,
        необходимом для достижения указанных выше целей, поручить обработку моих
        персональных данных по своему усмотрению третьим лицам, перечень которых размещен
        на сайте Банка
        <a href="https://uralsib.ru/s/pdn" target="_blank" rel="noreferrer">
          {' '}
          https://uralsib.ru/s/pdn
        </a>{' '}
        (вместе также именуемые Организация), в том числе некредитным и небанковским
        организациям, страховым компаниям, компаниям, осуществляющим рассылку (в т.ч.
        почтовую, электронную и SMS-сообщений), иным организациям связи, юридическим
        лицам, предоставляющим Банку услуги по урегулированию просроченной задолженности,
        и иным лицам на основании заключенных Банком с ними договоров, в т.ч. в целях
        проверки достоверности (актуальности) заявленных сведений; в целях подачи
        (представления) в Банк документов, необходимых для заключения договора на мое имя.
        Право выбора указанных компаний/ лиц предоставляется мной Банку и дополнительного
        согласования со мной не требуется, в случае необходимости разрешаю представлять
        третьим лицам соответствующие документы, содержащие мои персональные данные, с
        соблюдением требований действующего законодательства Российской Федерации, а также
        получать мои персональные данные от третьих лиц.
        <br />
        Обработка моих персональных данных может осуществляться
        Банком/Организацией/Обществом с использованием и без использования средств
        автоматизации и заключается в сборе, записи, систематизации, накоплении, хранении,
        уточнении (обновлении, изменении), извлечении, использовании, передаче третьим
        лицам (предоставление, доступ), обезличивании, блокировании, удалении, уничтожении
        и любом другом их использовании в соответствии с требованиями действующего
        законодательства Российской Федерации (далее - РФ).
        <br />
        Даю согласие Банку и Обществу на фотографирование и/или видеосъемку, сделанных в
        Банке, в целях подтверждения моей личности для заключения и исполнения кредитного
        договора с Банком, договора потребительского займа с Обществом и (или) договоров с
        третьими лицами, которым Банк вправе передавать мои персональные данные,
        предупреждения мошенничества и повышения качества обслуживания.
        <br />
        Настоящим признаю и подтверждаю, что Банком мне разъяснены юридические последствия
        отказа предоставить мои персональные данные и (или) дать согласие на их обработку.
        Не возражаю против обработки моих персональных данных в целях и способами,
        указанными в согласии.
        <br />
        Настоящее согласие действует в течение 10 (десяти) лет со дня его подачи. В случае
        заключения Договора настоящее согласие сохраняет действие в течение всего периода
        действия Договора и в течение 20 (двадцати) лет после прекращения срока действия
        Договора. Настоящее согласие на обработку моих персональных данных может быть
        отозвано мною путем подачи письменного уведомления в Банк.
      </p>

      <p>
        Даю Банку свое согласие на запрос основной части моей кредитной истории из любого
        бюро кредитных историй, включенного в государственный реестр бюро кредитных
        историй, информации обо мне, условиях, заключенного со мной кредитного договора,
        договора поручительства, договора займа, банковской гарантии, где я выступаю
        заемщиком, поручителем, принципалом соответственно, об их изменениях и
        дополнениях, об отказе от заключения кредитного договора либо предоставления
        кредита или его части, а также информации об исполнении мной обязательств по
        кредитному договору. Данное согласие предоставляю в целях заключения и исполнения
        кредитного договора, а также проверки благонадежности. Согласие на запрос
        кредитной истории считается действительным в течение 6 (шести) месяцев со дня его
        оформления. В случае, если в течение указанного срока кредитный договор/договор
        поручительства был заключен, указанное согласие сохраняет силу в течение всего
        срока действия кредитного договора/договора поручительства.
      </p>

      <p>
        Политика обработки персональных данных{' '}
        <a href="https://uralsib.ru/pdn" target="_blank" rel="noreferrer">
          www.uralsib.ru/pdn
        </a>
        .
      </p>
    </div>

    <h4>
      2.5 <span>Альфа банк</span>
    </h4>

    <div className={styles.frame}>
      <p>
        Согласие на обработку персональных данных, применяемое при направлении заявлений в
        электронном виде в информационнотелекоммуникационной сети «Интернет» на сайте АО
        «АЛЬФА-БАНК» или посредством услуги «Альфа-Мобайл» в целях получения услуг АО
        «АЛЬФА-БАНК» (далее — «Согласие»)
        <br />
        Я, (далее — «Заявитель»), свободно, своей волей и в своем интересе даю конкретное,
        предметное, информированное, сознательное и однозначное согласие АО «АЛЬФА-БАНК»
        (г. Москва, ул. Каланчевская, д. 27) (далее — «Банк») на обработку информации,
        относящейся к моим персональным данным, в том числе: Ф.И.О.; год, месяц, дата,
        место рождения; гражданство; пол; данные документа, удостоверяющего личность (тип,
        серия, номер, кем и когда выдан), в том числе за пределами Российской Федерации,
        включая их копии; адрес: места жительства, места регистрации, места работы; ИНН;
        СНИЛС, полис обязательного медицинского страхования; полис добровольного
        медицинского страхования; полис КАСКО; информация о состоянии индивидуального
        лицевого счета застрахованного лица; данные водительского удостоверения, включая
        его копию; сведения о занятости, трудовой деятельности (в том числе сведения о
        трудовом стаже, доходах и расходах), семейное положение, имущественное положение,
        образование, профессия; фотографическое изображение и видеоизображение; сведения
        из кредитной истории, сведения о счетах и картах, о размере задолженности перед
        кредиторами, иная, ранее предоставленная Банку информация; сведения о номерах
        телефонов, абонентом и/или пользователем которых я являюсь; сведения об оказанных
        операторами связи услугах связи (в том числе сведений о местонахождении
        абонентского оборудования при получении услуг связи, сведения о трафике, оказанных
        услугах и их оплате), сведения об идентификаторах абонентского оборудования, иные
        сведения о Заявителе как об абоненте, сведения о результатах их обработки, в том
        числе организация канала коммуникации Банка с использованием телефонных номеров,
        абонентом и/или пользователем которых я являюсь; сведения об адресах электронной
        почты Заявителя, имени пользователя Заявителя в сети Интернет, данные о созданном
        на сайте Банка или мобильном приложении аккаунте (учетной записи); метаданные,
        данные cookie-файлов, cookie-идентификаторы, IP-адреса, сведения о браузере и
        операционной системе; сведения, полученные от третьих лиц, в том числе
        государственных органов, государственных информационных систем, единой системы
        идентификации и аутентификации (ЕСИА), Пенсионного фонда Российской Федерации, в
        том числе через систему межведомственного электронного взаимодействия (СМЭВ),
        и/или из сети Интернет, и/или из иных общедоступных источников персональных данных
        и любую иную информацию, представленную Банку.
      </p>

      <p>
        Обработка персональных данных может осуществляться с использованием средств
        автоматизации или без таковых, а также путем смешанной обработки персональных
        данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), извлечение, использование, передачу (предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в
        том числе в информационных системах Банка, и совершение иных действий,
        предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных
        данных».
      </p>

      <p>Целями обработки персональных данных является:</p>

      <p>• заключение с Банком и третьими лицами любых договоров и соглашений;</p>

      <p>• проверка достоверности указанных Заявителем сведений;</p>

      <p>• получение персональных данных Заявителя из иных разрешенных источников;</p>

      <p>
        • проверка и оценка платежеспособности и кредитоспособности Заявителя, получение
        результатов такой оценки, кредитных оценок (скорингов), скорингового балла
        (индивидуального рейтинга) и других показателей благонадежности при рассмотрении
        Банком возможности заключения с Заявителем любых договоров и соглашений;
      </p>

      <p>• подбор и предоставление Банком услуг Заявителю;</p>

      <p>
        • предоставление Заявителю информации о результатах рассмотрения Банком Заявления;
      </p>

      <p>
        • создание информационных систем данных, анализ, моделирование, прогнозирование,
        построение математических (скоринг) моделей, анализ агрегированных и анонимных
        данных, статистические и исследовательские цели;
      </p>

      <p>• хранение данных Банком и третьими лицами;</p>

      <p>
        • продвижение продуктов и услуг Банка и/или третьих лиц, в том числе передача
        информационных и рекламных сообщений об услугах Банка и/или третьих лиц путем
        осуществления прямых контактов с помощью средств связи и иным способом.
      </p>

      <p>
        В вышеуказанных целях Заявитель дает согласие на обработку перечисленных
        персональных данных Банку и поручение обработки третьим лицам, в том числе
        перечень которых размещен на сайте Банка по ссылке{' '}
        <a href="https://alfa.link/partners" target="_blank" rel="noreferrer">
          alfa.link/partners
        </a>{' '}
        («Перечень третьих лиц, которые осуществляют обработку персональных данных
        Клиентов на основании заключенных ими с Банком договоров, согласий Клиентов»)
        (далее — «Третьи лица»), а также их получение от Третьих лиц.
      </p>

      <p>
        Заявитель, являющийся пользователем абонентского номера, указанного в заявлении в
        электронном виде на сайте Банка (далее — «Пользователь»), в целях предоставления
        Банком услуг выражает согласие операторам связи, указанным в Перечне, на обработку
        номеров телефонов, пользователем которых является Пользователь, сведений об
        оказанных операторами услугах связи (в том числе сведений о местонахождении
        абонентского оборудования при получении услуг связи, оплате оказанных услуг
        связи), сведений об идентификаторах абонентского оборудования и передачу указанных
        сведений или результата их обработки Банку, в том числе организацию канала
        коммуникации Банка с использованием телефонных номеров, используемых
        Пользователем.
      </p>

      <p>
        Банк осуществляет обработку персональных данных Заявителя в течение 3 (трех) лет
        со дня подачи настоящего Согласия. В случае, если в течение указанного срока с
        Заявителем будет заключен договор потребительского кредита и/или договор о
        банковском обслуживании, Согласие Заявителя сохраняет силу в течение всего срока
        действия каждого договора, а также в течение 10 (десяти) лет с даты прекращения
        обязательств сторон по договорам. В случае отказа Банка от заключения с Заявителем
        договоров Банк осуществляет дальнейшее хранение персональных данных Заявителя в
        течение 10 (десяти) лет.
      </p>

      <p>
        Согласие может быть отозвано субъектом персональных данных путем обращения в
        отделение Банка с заявлением, оформленным в письменной форме.
      </p>
    </div>

    <h4>
      2.6 <span>А-деньги</span>
    </h4>

    <div className={styles.frame}>
      <p>
        Я, (далее — «Заявитель»), свободно, своей волей и в своем интересе, руководствуясь
        ст. 9 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных»,
        подписанием настоящего согласия на обработку персональных данных (далее —
        «Согласие») даю конкретное, информированное и сознательное согласие на обработку
        ООО МКК «А ДЕНЬГИ» (ОГРН 11 217 700 636 944, ИНН 7 708 400 979, адрес: 107 078, г.
        Москва, вн. тер. г. Муниципальный округ Красносельский, ул. Маши Порываевой, д. 7,
        стр. а, этаж/помещ./ком. 8/5-6Б/805-806) (далее — «МКК») следующей информации,
        относящейся к моим персональным данным, а именно: фамилия, имя, отчество, пол,
        гражданство, дата и место рождения, наименование и реквизиты документа,
        удостоверяющего личность, данные водительского удостоверения, адрес регистрации по
        месту жительства, адрес регистрации по месту пребывания, адрес фактического
        проживания, идентификационный номер налогоплательщика, страховой номер
        индивидуального лицевого счета, указанный в страховом свидетельстве обязательного
        пенсионного страхования, наименование и реквизиты работодателя или учебного
        заведения, номер мобильного телефона, адрес электронной почты, принадлежность
        Заявителя к российским/иностранным публичным должностным лицам, принадлежность
        Заявителя к должностным лицам публичной международной организации, фотографическое
        и видеоизображение, аудиозапись голоса, иная информация, предоставленная
        Заявителем в адрес МКК, а также полученная МКК из общедоступных источников
        персональных данных или от Третьих лиц или иных лиц, в том чисел государственных
        органов, государственных информационных систем и иных источников, получение
        информации из которых предусмотрено действующим законодательством Российской
        Федерации (далее — «Данные»).
      </p>

      <p>Заявитель предоставляет МКК свое согласие на обработку МКК Данных для целей:</p>

      <p>• получения Заявителем услуг и предоставления сервисов МКК;</p>

      <p>• проверки достоверности указанных Заявителем Данных;</p>

      <p>
        • проверки и оценки платежеспособности и кредитоспособности для принятия решения о
        заключении Договора потребительского займа;
      </p>

      <p>• получения результатов оценки и иных показателей благонадежности Заявителя;</p>

      <p>
        • рассмотрения МКК вопроса о возможности предоставления Заявителю потребительского
        займа, согласования условий договоров и соглашений с МКК;
      </p>

      <p>
        • заключения и исполнения Договора потребительского займа и иных договоров и (или)
        соглашений с МКК;
      </p>

      <p>
        • улучшения клиентского опыта, качества обслуживания МКК, организации улучшения
        программного и иного технического обеспечения МКК;
      </p>

      <p>
        • осуществления взыскания просроченной задолженности перед МКК по любому договору
        и (или) соглашению;
      </p>

      <p>
        • создания информационных систем данных, анализа, моделирования, прогнозирования,
        построения математических моделей, профилирования, таргетирования, построения
        скоринг-моделей, их использования и передачи результатов обработки информации
        Третьим лицам, обогащения и сегментации для формирования и адаптации возможных
        услуг и предложений МКК и Третьих лиц, анализа агрегированных и анонимных данных,
        статистических и исследовательских целях;
      </p>

      <p>
        • предоставления МКК сторонними организациями услуг по хранению клиентских
        документов, данных, создания, передачи, хранения электронных копий указанных
        документов, в том числе распознавания сканированных образов таких документов;
      </p>

      <p>• предоставления Заявителю информации об услугах, оказываемых МКК;</p>

      <p>
        • продвижения продуктов и услуг МКК путем осуществления прямых контактов с помощью
        средств связи, посредством телефонной связи, по сетям подвижной радиотелефонной
        связи и иным способом, чата, рассылок;
      </p>

      <p>
        • продвижения продуктов и услуг Третьих лиц, в том числе передача информационных и
        рекламных сообщений об услугах Третьих лиц путем осуществления прямых контактов с
        помощью любых доступных Заявителю средств связи;
      </p>

      <p>
        • иных целях, которые будут предусмотрены в заключенным между Заявителем и МКК
        договорах и (или) соглашениях, в том числе Договоре потребительского займа.
      </p>

      <p>
        В вышеуказанных целях Заявитель дает согласие на обработку Данных МКК и / или
        третьим лицам, перечень которых размещен на официальном сайте МКК в
        информационно-телекоммуникационной сети «Интернет» по адресу:{' '}
        <a href="https://adengi.ru" target="_blank" rel="noreferrer">
          adengi.ru
        </a>{' '}
        (именуемым по тексту Согласия — «Третьи лица»), с которыми у МКК заключено
        соглашение, содержащее условия об обработке персональных данных в объеме,
        необходимом для исполнения соглашения, а также условия о конфиденциальности и
        неразглашении информации.
      </p>

      <p>
        Обработка Данных может осуществляться с использованием средств автоматизации или
        без таковых, а также путем смешанной обработки, включая сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, анализ и использование, копирование, передачу (предоставление, доступ)
        и поручение обработки Третьим лицам, а также получение от Третьих лиц,
        обезличивание блокирование, удаление, уничтожение персональных данных, в том числе
        в информационных системах МКК, а также совершение иных действий, предусмотренных
        Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных».
      </p>

      <p>
        Заявитель выражает согласие на получение МКК из бюро кредитных историй информации
        об основной кредитной истории Заявителя в соответствии с Федеральным законом от
        30.12.2004 г. № 218-ФЗ «О кредитных историях» в целях осуществления МКК проверки
        благонадежности Заявителя и /или для формирования МКК в отношении Заявителя
        предложений услуг, принятия решения МКК о предоставлении Заявителю займа (-ов),
        заключения с Заявителем и дальнейшего сопровождения (исполнения) договоров и (или)
        соглашений.
      </p>

      <p>
        Заявитель в случае заключения с МКК Договора потребительского займа выражает своей
        согласие на взаимодействие с третьими лицами, информация о которых предоставлена
        Заявителем в МКК для целей возврата просроченной задолженности. Заявитель заявляет
        и гарантирует, что им получено письменное согласие третьих лиц, чьи данные он
        предоставил МКК / содержатся в документах, предоставленных Заявителем в МКК, на
        передачу и обработку их персональных данных МКК. В случае направления МКК
        претензий третьими лицами, по факту обработки МКК их персональных данных,
        полученных от Заявителя, МКК вправе запросить у Заявителя, а Заявитель обязан
        предоставить подтверждение получения согласия третьих лиц на передачу и обработку
        их персональных данных МКК.
      </p>

      <p>
        Заявитель даёт согласие в соответствии со ст. 152.1 Гражданского кодекса
        Российской Федерации на обработку и хранение изображений гражданина (фотографии,
        видеозаписи), полученных в процессе съёмки. Заявитель выражает согласие на
        получение рекламы, предоставление ему информации и предложения продуктов МКК и
        (или) Третьих лиц путем направления на адреса электронной почты, телефонных
        обращений, SMS-сообщений на телефонные номера, по сети подвижной радиотелефонной
        связи и иным способом.
      </p>

      <p>
        Согласие может быть отозвано Заявителем путем направления письменного заявления в
        адрес МКК. В случае отзыва Согласия МКК вправе продолжить обработку персональных
        данных без согласия Заявителя при наличии иных оснований, определенных Федеральным
        законом от 27.07.2006 г. № 152-ФЗ «О персональных данных», в том числе для
        хранения персональных данных, предусмотренного законодательством РФ, в целях
        исполнения условий любого заключенного между Заявителем и МКК договора и (или)
        соглашения или исполнения требований законодательства РФ.
      </p>

      <p>
        МКК осуществляет обработку персональных данных Заявителя в течение всего срока
        действия договоров и (или) соглашений, включая Договор потребительского займа,
        заключенных с МКК, а также в течение 5 (пяти) лет с даты прекращения обязательств
        сторон по договорам и (или) соглашениям.
      </p>

      <p>
        Заявитель осознает, что несет ответственность за предоставленные им персональные и
        иные данные, включая их полноту, достоверность, недвусмысленность и относимость
        непосредственно к нему, а также за предоставление МКК персональных данных третьих
        лиц.
      </p>
    </div>
  </div>
);

export default ProcessingDoc;
