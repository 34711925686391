import React, { useContext } from 'react';
import imageDesktop from '../../assets/banner/home/woman-desktop.webp';
import imageMobile from '../../assets/banner/home/woman-phone.webp';
import { MediaContext } from '../../context/MediaProvider';
import com from '../../styles/common.module.scss';
import { ReactComponent as Logo } from '../../assets/logo/logo.svg';
import { BannerTextBlock } from './components/BannerTextBlock';
import s from './style/Banner.module.scss';

import { ReactComponent as User } from 'assets/icons/user.svg';

export const Banner: React.FC = () => {
  const tablet = useContext(MediaContext);

  const openModalHandler = () => {
    window.open(window.location.href);
    window.location.replace(`https://zaim-exp.ru/${window.location.search}`);
    localStorage.setItem('isReplace', 'true');
  };

  return (
    <>
      <section className={s.banner}>
        <div className={com.container}>
          <div className={s.banner__logo}>
            <Logo />

            <button
              onClick={openModalHandler}
              className={s.banner__personal_btn}
              type="button"
            >
              <User />
              Личный кабинет
            </button>
          </div>
          <div className={s.banner__body}>
            <div className={`${s.banner__column} ${s.banner__image}`}>
              <picture>
                <source
                  srcSet={imageDesktop}
                  type="image/webp"
                  media="(min-width: 767.98px)"
                />
                <source
                  srcSet={imageMobile}
                  type="image/webp"
                  media="(max-width: 767.98px)"
                />
                <img src={imageDesktop} alt="card" />
              </picture>
            </div>
            <div className={`${s.banner__column} ${s.banner__text_block}`}>
              <BannerTextBlock subtitle button />
            </div>
          </div>
        </div>
      </section>
      {!tablet && (
        <div className={com.container}>
          <BannerTextBlock className={s.banner__mobile} subtitle button />
        </div>
      )}
    </>
  );
};
