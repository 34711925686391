import React, { FC, memo } from 'react';

import { Pdf } from '../../components/Pdf/Pdf';
import DocumentModel from '../../models/DocumentModel/DocumentModel';

const DocumentContainer: FC = memo(() => {
  const { documentState, closePdf } = DocumentModel();

  const ownerInfo = {
    owner: 'ООО «ФИН ПРОМОУТ СИСТЕМС»',
    inn: '6671285919',
    ogrn: '1236600060290',
    licenseNumber: '',
    orderNumber: '',
    address: {
      registrationAddress: '',
      factAddress: '',
    },
  };
  const style = {
    containerStyle: {
      paddingTop: 0,
    },
    headerStyle: {},
    buttonStyle: {},
  };

  return (
    <Pdf
      theme="sobank"
      origin={window.location.origin}
      closeHandler={closePdf}
      name="Сервис"
      secondName="Сервис"
      {...ownerInfo}
      {...documentState}
      {...style}
    />
  );
});

export default DocumentContainer;
