import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';
import { documentType } from '../../store/documentSlice';
import { closeDoc, openDoc } from '../../store/documentSlice/documentSlice';

const DocumentModel = () => {
  const dispatch = useAppDispatch();
  const documentState = useAppSelector(state => state.document);

  const closePdf = useCallback(() => {
    dispatch(closeDoc());
  }, []);

  const openPdf = useCallback((doc: documentType) => {
    dispatch(openDoc(doc));
  }, []);

  const result = useMemo(
    () => ({
      documentState,
      closePdf,
      openPdf,
    }),
    [documentState],
  );

  return { ...result };
};

export default DocumentModel;
