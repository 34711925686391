import React from 'react';

import com from '../../styles/common.module.scss';

import { howItWorksData } from './data/data';
import s from './style/HowItWorks.module.scss';

const INC_INDEX = 1;
const PAD_START_MAX_LENGTH = 2;

export const HowItWorks: React.FC = () => (
  <section className={com.container}>
    <div className={s.works}>
      <h2 className={com.title__h2_landing}>Как это работает?</h2>
      <div className={s.works__items}>
        {howItWorksData.map((card, i) => (
          <article className={s.works__item} key={card.title}>
            <p className={s.works__number}>{`${String(i + INC_INDEX).padStart(
              PAD_START_MAX_LENGTH,
              '0',
            )}`}</p>
            <div className={s.works__icon}>
              <img src={card.icon} alt="HowItWorks icon" />
            </div>
            <h3 className={s.works__title}>{card.title}</h3>
            <p className={s.works__subtitle}>{card.subtitle}</p>
          </article>
        ))}
      </div>
    </div>
  </section>
);
