import { createContext, FC } from 'react';

import { tabletWidth } from '../constants';
import { useMedia } from '../hook/useMedia';

export const MediaContext = createContext(false);

interface MediaProviderPropsType {
  children: JSX.Element;
}

export const MediaProvider: FC<MediaProviderPropsType> = ({ children }) => {
  const media = useMedia(tabletWidth);
  return <MediaContext.Provider value={media}>{children}</MediaContext.Provider>;
};
