export const questionData = (mobile: boolean) => [
  {
    title: 'Кто может стать нашим клиентом?',
    description: 'Лица достигшие 18 лет',
  },
  {
    title: 'Как получить займ?',
    description: 'На карту любого\n банка и электронный\n кошелек',
  },
  {
    title: 'Как оплатить займ?',
    description:
      'Через платежные\n терминалы или по\n реквизитам\n микрофинансовой\n организации',
  },
  {
    title: `Какие документы ${mobile ? 'необходимы' : 'нужны'} для получения займа?`,
    description: 'Паспорт и действующий мобильный номер телефона',
  },
  {
    title: 'Сколько обрабатывается заявка?',
    description: 'Обработка заявки длится от 5 минут до\n 24 часов',
  },
];
