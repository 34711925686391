import React, { FC } from 'react';

import styles from './DocStyles.module.scss';

import { DocumentParametersType, partnerList } from './index';

const PoliticsDoc: FC<DocumentParametersType> = ({ origin }) => (
  <div className={styles.docStyleContainer}>
    <h1>Политика относительно обработки персональных данных</h1>
    <ol id="l1">
      <li data-list-text="1.">
        <h1>1. Общие положения</h1>
        <ol id="l2">
          <li data-list-text="1.1.">
            <p>
              1.1 Настоящая Политика, в отношении обработки персональных данных (далее -
              Политика) разработана во исполнение требований п. 2 ч. 1 ст. 18.1
              Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее –
              Закон о персональных данных) в целях обеспечения защиты прав и свобод
              человека и гражданина при обработке его персональных данных, в том числе
              защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </p>
          </li>
          <li data-list-text="1.2.">
            <p>
              1.2 Политика действует в отношении всех персональных данных, которые
              обрабатывает оператор.
            </p>
          </li>
          <li data-list-text="1.3.">
            <p>
              1.3 Оператор персональных данных и внесен в реестр операторов персональных
              данных.
            </p>
          </li>
          <li data-list-text="1.4.">
            <p>
              1.4 Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных
              настоящая Политика публикуется в свободном доступе в
              информационно-телекоммуникационной сети Интернет на сайте Оператора
              <a href={origin} target="_blank" rel="noreferrer">
                <span>{origin}.</span>
              </a>
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <h1>2. Термины и принятые сокращения</h1>
        <span>
          2.1 Персональные данные{' '}
          <span className="p">
            – любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных).
          </span>
        </span>
        <span>
          2.2 Субъект персональных данных{' '}
          <span className="p">
            – физическое лицо, в отношении которого в соответствии с законом
            осуществляется обработка персональных данных.
          </span>
        </span>
        <span>
          2.3 Персональные данные, разрешенные субъектом персональных данных для
          распространения{' '}
          <span className="p">
            – это персональные данные, доступ неограниченного круга лиц к которым
            предоставлен субъектом персональных данных путем дачи согласия на обработку
            персональных данных, разрешенных субъектом персональных данных для
            распространения.
          </span>
        </span>
        <span>
          2.4 Оператор персональных данных{' '}
          <span className="p">
            (Оператор) – государственный орган, муниципальный орган, юридическое или
            физическое лицо, самостоятельно или совместно с другими лицами организующие и
            (или) осуществляющие обработку персональных данных, а также определяющие цели
            обработки персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными.
          </span>
        </span>
        <span>
          2.5 Обработка персональных данных{' '}
          <span className="p">
            – любое действие (операция) или совокупность действий (операций) с
            персональными данными, совершаемых с использованием средств автоматизации или
            без их использования. Обработка персональных данных включает в себя в том
            числе:
          </span>
        </span>
        <ul id="l3" className={styles.list}>
          <li data-list-text="">
            <p>сбор;</p>
          </li>
          <li data-list-text="">
            <p>запись;</p>
          </li>
          <li data-list-text="">
            <p>систематизацию;</p>
          </li>
          <li data-list-text="">
            <p>накопление;</p>
          </li>
          <li data-list-text="">
            <p>хранение;</p>
          </li>
          <li data-list-text="">
            <p>уточнение (обновление, изменение);</p>
          </li>
          <li data-list-text="">
            <p>извлечение;</p>
          </li>
          <li data-list-text="">
            <p>использование;</p>
          </li>
          <li data-list-text="">
            <p>передачу (предоставление, доступ);</p>
          </li>
          <li data-list-text="">
            <p>распространение;</p>
          </li>
          <li data-list-text="">
            <p>обезличивание;</p>
          </li>
          <li data-list-text="">
            <p>блокирование;</p>
          </li>
          <li data-list-text="">
            <p>удаление;</p>
          </li>
          <li data-list-text="">
            <p>уничтожение.</p>
          </li>
        </ul>
        <span>
          2.6 Автоматизированная обработка персональных данных{' '}
          <span className="p">
            – обработка персональных данных с помощью средств вычислительной техники.
          </span>
        </span>
        <span>
          2.7 Предоставление персональных данных{' '}
          <span className="p">
            – действия, направленные на раскрытие персональных данных определенному лицу
            или определенному кругу лиц.
          </span>
        </span>
        <span>
          2.8 Распространение персональных данных{' '}
          <span className="p">
            – действия, направленные на раскрытие персональных данных неопределенному
            кругу лиц.
          </span>
        </span>
        <span>
          2.9 Блокирование персональных данных{' '}
          <span className="p">
            – временное прекращение обработки персональных данных (за исключением случаев,
            если обработка необходима для уточнения персональных данных).
          </span>
        </span>
        <span>
          2.10 Уничтожение персональных данных{' '}
          <span className="p">
            – действия, в результате которых становится невозможным восстановить
            содержание персональных данных в информационной системе персональных данных и
            (или) в результате которых уничтожаются материальные носители персональных
            данных.
          </span>
        </span>
        <span>
          2.11 Обезличивание персональных данных{' '}
          <span className="p">
            – действия, в результате которых становится невозможным без использования
            дополнительной информации определить принадлежность персональных данных
            конкретному субъекту персональных данных.
          </span>
        </span>
        <span>
          2.12 Информационная система персональных данных{' '}
          <span className="p">
            – совокупность содержащихся в базах данных персональных данных и
            обеспечивающих их обработку, информационных технологий и технических средств.
          </span>
        </span>
        <span>
          2.13 Защита персональных данных{' '}
          <span className="p">
            – деятельность, направленная на предотвращение утечки защищаемых персональных
            данных, несанкционированных и непреднамеренных воздействий на защищаемые
            персональные данные.
          </span>
        </span>
        <span>
          2.14 Конфиденциальность персональных данных{' '}
          <span className="p">
            – обязательное для соблюдения лицом, получившим доступ к Персональным данным,
            требование не раскрывать третьим лицам и не распространять Персональные данные
            без согласия Субъекта персональных данных или наличия иного законного
            основания, предусмотренного федеральным законом.
          </span>
        </span>
      </li>
      <li data-list-text="3.">
        <h1>3. Цели обработки персональных данных</h1>
        <ol id="l4">
          <li data-list-text="3.1.">
            <p>3.1 Оператор осуществляет обработку персональных данных в целях:</p>
            <ul id="l5" className={styles.list}>
              <li data-list-text="-">
                <p>
                  идентификации Субъекта персональных данных, подтверждения его личности,
                  регистрации на Сайте/ в Мобильном приложении Оператора;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  предоставления Субъекту персональных данных информации о предложениях по
                  продуктам и услугам, оказываемых Оператором и его Партнерами;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  продвижения продуктов и услуг Партнеров, в том числе передачи
                  информационных и рекламных сообщений об услугах Партнеров путем
                  осуществления контактов с помощью средств связи;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  рассмотрения возможности предоставления Субъекту персональных данных
                  финансовых или иных услуг;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  информирования Субъекта персональных данных о ходе исполнения
                  оказываемых услуг;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  заключения с Субъектом персональных данных любых договоров и их
                  дальнейшего исполнения, изменения и прекращения;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  осуществления маркетинговых исследований и анализа их результатов,
                  проведения акций, опросов, исследований, рекламных программ, продвижения
                  услуг Оператора;
                </p>
              </li>
              <li data-list-text="-">
                <p>создания баз данных;</p>
              </li>
              <li data-list-text="-">
                <p>
                  ведения статистики о пользователях Сайта/Мобильного приложения
                  Оператора, отслеживания данных о пользовательском устройстве, данных о
                  времени посещения Сайта, состояния сессии, обеспечения функционирования
                  и улучшения качества сайта Оператора;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  организации кадрового учета работников Оператора, заключения/исполнения
                  обязательств по трудовым и гражданско-правовым договорам, ведения
                  кадрового делопроизводства;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  заполнения и передачи в органы исполнительной власти и иные
                  уполномоченные организации требуемых форм отчетности;
                </p>
              </li>
              <li data-list-text="-">
                <p>осуществления гражданско-правовых отношений.</p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
      <li data-list-text="4.">
        <h1>4. Правовые основания обработки персональных данных</h1>
        <ol id="l6">
          <li data-list-text="4.1.">
            <p>
              4.1 Правовым основанием обработки персональных данных является наличие
              согласия Субъекта персональных данных, а также совокупность правовых актов,
              во исполнение которых и в соответствии с которыми Оператор осуществляет свою
              деятельность и обработку персональных данных: Конституции Российской
              Федерации; Гражданского кодекса Российской Федерации; Налогового кодекса
              Российской Федерации; Трудового кодекса Российской Федерации; Федерального
              закона от 08.02.1998 № 14-ФЗ «Об обществах с ограниченной ответственностью»;
              Федерального закона от 27.07.2006 г. № 149-ФЗ «Об информации, информационных
              технологиях и о защите информации»; Федерального закона от 01.04.1996 №
              27-ФЗ «Об индивидуальном (персонифицированном) учете в системе обязательного
              пенсионного страхования», Федерального закона от 06.04.2011 №63-ФЗ «Об
              электронной подписи».
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="5.">
        <h1>
          5. Объем и категории обрабатываемых персональных данных, категории субъектов
          персональных данных
        </h1>
        <ol id="l7">
          <li data-list-text="5.1.">
            <p>
              5.1 При определении состава обрабатываемых персональных данных Оператор
              руководствуется минимально необходимым перечнем персональных данных для
              достижения целей обработки персональных данных.
            </p>
          </li>
          <li data-list-text="5.2.">
            <p>
              5.2 К категориям субъектов персональных данных, чьи персональные данные
              обрабатываются Оператором, относятся:
            </p>
            <ul id="l8" className={styles.list}>
              <li data-list-text="-">
                <p>физические лица, являющиеся кандидатами на работу (соискатели);</p>
              </li>
              <li data-list-text="-">
                <p>
                  физические лица, являющиеся работниками Оператора (в т.ч. уволенные);
                </p>
              </li>
              <li data-list-text="-">
                <p>родственники работников;</p>
              </li>
              <li data-list-text="-">
                <p>
                  физические лица, оказывающие услуги (работы) на основании
                  гражданско-правовых договоров;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  физические лица представители и работники компаний контрагентов
                  Оператора;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  физические лица, являющиеся пользователями Сайта/Мобильного приложения
                  Оператора и услуг, предоставляемых на них;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  физические лица, персональные данные которых сделаны ими общедоступными,
                  а их обработка не нарушает их прав и соответствует требованиям,
                  установленным законодательством о персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  иные физические лица, выразившие согласие на обработку Оператором их
                  персональных данных.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="5.3.">
            <p>
              5.3 При обработке Оператором персональных данных не допускается сбор
              избыточных персональных данных по отношению к заявленным целям обработки. В
              состав обрабатываемых персональных данных могут входить: фамилия, имя,
              отчество; гражданство; пол; год, месяц, дата и место рождения; адрес места
              жительства; почтовый адрес; номер и серия документа, удостоверяющего
              личность, сведения о дате выдачи указанного документа и выдавшем его органе;
              место работы; адрес работы; стаж; должность; общий ежемесячный доход;
              образование; семейное положение; количество детей; данные о наличии
              автомобиля, недвижимости; номера контактных телефонов (в том числе
              мобильного); адрес электронной почты; ИНН, СНИЛС; электронные данные,
              включая, но не ограничиваясь о пользовательском устройстве (среди которых
              разрешение, версия и другие атрибуты, характеризуемые пользовательское
              устройство); данные, характеризующие аудиторные сегменты, параметры сессии,
              данные о времени посещения Сайта; идентификатор пользователя, хранимый в
              cookie-файлах; Cookie-файлы, IP-адрес; данные о доверенном лице иные
              сведения о Субъекте персональных данных в зависимости от оказываемых
              Оператором услуг и осуществляемых операций.
            </p>
          </li>
          <li data-list-text="5.4.">
            <p>
              5.4 Оператор не осуществляет обработку специальных категорий персональных
              данных, касающихся расовой и национальной принадлежности, политических
              взглядов, религиозных и философских убеждений, состояния здоровья, интимной
              жизни и судимости физических лиц.
            </p>
          </li>
          <li data-list-text="5.5.">
            <p>
              5.5 Оператор вправе осуществлять обработку сведений о состоянии здоровья в
              соответствии с Трудовым кодексом, Федеральным законом № 326 «Об обязательном
              медицинском страховании в РФ», а также п.2.3 ч.2 ст.10 Федерального закона №
              152 «О персональных данных».
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="6.">
        <h1>6. Порядок и условия обработки персональных данных</h1>
        <ol id="l9">
          <li data-list-text="6.1.">
            <p>
              6.1 Обработка персональных данных Оператором осуществляется на основе
              следующих принципов:
            </p>
            <ul id="l10" className={styles.list}>
              <li data-list-text="-">
                <p>законность и справедливость обработки персональных данных;</p>
              </li>
              <li data-list-text="-">
                <p>
                  обработка персональных данных ограничивается достижением конкретных,
                  заранее определенных и конкретных целей обработки;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  не допускается обработка персональных данных, несовместимая с целями
                  сбора персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  соответствие объема и характера обрабатываемых персональных данных,
                  способов обработки персональных данных целям обработки;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  достоверности персональных данных, их достаточности для целей обработки,
                  недопустимости обработки персональных данных, избыточных по отношению к
                  целям, заявленным при сборе персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  не допускается объединение созданных для несовместимых между собой целей
                  баз данных, содержащих персональные данные;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  хранение персональных данных осуществляется в форме, позволяющей
                  определить Субъекта персональных данных, не дольше, чем этого требуют
                  цели обработки персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  производится уничтожение персональных данных либо обезличивание по
                  достижении целей обработки или в случае утраты необходимости в
                  достижении этих целей, если иное не предусмотрено федеральным законом;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  обеспечения конфиденциальности и безопасности обрабатываемых
                  персональных данных.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.2.">
            <p>
              6.2 Оператор осуществляет сбор, запись, систематизацию, накопление,
              хранение, уточнение (обновление, изменение), извлечение, использование,
              передачу (распространение, предоставление, доступ), обезличивание,
              блокирование, удаление и уничтожение персональных данных.
            </p>
          </li>
          <li data-list-text="6.3.">
            <p>6.3 Обработка персональных данных осуществляется следующими способами:</p>
            <ul id="l11" className={styles.list}>
              <li data-list-text="-">
                <p>неавтоматизированная обработка персональных данных;</p>
              </li>
              <li data-list-text="-">
                <p>автоматизированная обработка персональных данных;</p>
              </li>
              <li data-list-text="-">
                <p>смешанная обработка персональных данных.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.4.">
            <p>6.4 Согласие на обработку персональных данных:</p>
            <ul id="l12" className={styles.list}>
              <li data-list-text="6.4.1.">
                <p>
                  Обработка персональных данных осуществляется на основании Согласия на
                  обработку персональных данных, а также условий, определенных
                  законодательством Российской Федерации. Согласие на обработку
                  персональных данных может быть дано Субъектом персональных данных или
                  его представителем в любой позволяющей подтвердить факт его получения
                  форме. В случае получения согласия на обработку персональных данных от
                  представителя субъекта персональных данных полномочия данного
                  представителя на дачу согласия от имени субъекта персональных данных
                  проверяются Оператором.
                </p>
              </li>
              <li data-list-text="6.4.2.">
                <p>
                  Равнозначным содержащему собственноручную подпись Субъекта персональных
                  данных согласию в письменной форме на бумажном носителе признается
                  согласие в форме электронного документа, подписанного простой
                  электронной подписью (аналог собственноручной подписи) в соответствии с
                  требованиями законодательства.
                </p>
              </li>
              <li data-list-text="6.4.3.">
                <p>
                  Согласие на обработку персональных данных может быть дано Субъектом
                  персональных данных или его представителем в любой, позволяющей
                  подтвердить факт его получения форме, если иное не установлено
                  федеральным законом, в том числе посредством совершения конклюдентных
                  действий.
                </p>
              </li>
              <li data-list-text="6.4.4.">
                <p>
                  Оператор имеет право осуществлять обработку персональных данных без
                  наличия согласия Субъекта персональных данных, либо в случае отзыва
                  такого согласия только при наличии оснований, указанных в ч.2 ст.9
                  Федерального закона №152-ФЗ «О персональных данных».
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.5.">
            <p>6.5 Передача персональных данных:</p>
            <ul id="l13" className={styles.list}>
              <li data-list-text="6.5.1.">
                <p>
                  Оператор вправе передавать персональные данные органам дознания и
                  следствия, иным уполномоченным органам по основаниям, предусмотренным
                  действующим законодательством Российской Федерации.
                </p>
              </li>
              <li data-list-text="6.5.2.">
                <p>
                  При поручении обработки третьему лицу Оператор поручает такую обработку
                  с согласия субъекта персональных данных и на основании заключаемого с
                  этим лицом договора, с включением в такой договор обязательных положений
                  по соблюдению принципов и правил обработки персональных данных,
                  предусмотренных Федеральным законом № 152-ФЗ «О персональных данных».
                </p>
              </li>
              <li data-list-text="6.5.3.">
                <p>
                  Перечень третьих лиц, которым в рамках осуществления договорных
                  отношений поручается обработка персональных данных, указывается на
                  официальном сайте Оператора.
                </p>
              </li>
              <li data-list-text="6.5.4.">
                <p>
                  Право доступа к персональным данным Субъектов персональных данных на
                  бумажных и электронных носителях имеют работники Оператора в
                  соответствии с их должностными обязанностями.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.6.">
            <p>6.6 Хранение и сроки обработки персональных данных:</p>
            <ul id="l14" className={styles.list}>
              <li data-list-text="6.6.1.">
                <p>
                  При осуществлении хранения персональных данных Оператор использует базы
                  данных, находящиеся на территории Российской Федерации, в соответствии с
                  ч. 5 ст. 18 Федерального закона № 152-ФЗ «О персональных данных».
                </p>
              </li>
              <li data-list-text="6.6.2.">
                <p>
                  В Согласии на обработку персональных данных Субъекта персональных данных
                  указываются сроки хранения, в рамках которых Оператор осуществляется
                  обработка персональных данных, с которыми соглашается Субъект
                  персональных данных и которые могут быть более длительными, чем сроки,
                  установленные действующим законодательством Российской Федерации.
                </p>
              </li>
              <li data-list-text="6.6.3.">
                <p>
                  Создание фото- и видеоизображений в помещениях Оператора и на
                  прилегающей территории может производиться Оператором с целью контроля
                  соблюдения законности и правопорядка, а также предотвращения
                  противоправных действий, экстремистских проявлений и террористических
                  актов. Указанные фото- и видеоизображения, если иное не заявлено, не
                  используются с целью идентификации Субъектов персональных данных и не
                  рассматриваются Оператором как биометрические персональные данные.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.7.">
            <p>6.7 Уничтожение персональных данных:</p>
            <ul id="l15" className={styles.list}>
              <li data-list-text="6.7.1.">
                <p>
                  Персональные данные Субъектов персональных данных подлежат уничтожению
                  либо обезличиванию в следующих случаях, если иное не предусмотрено
                  соглашением, договором или федеральным законом:
                </p>
                <ul id="l16">
                  <li data-list-text="-">
                    <p>выявления неправомерной обработки данных;</p>
                  </li>
                  <li data-list-text="-">
                    <p>
                      достижения целей обработки персональных данных или утраты
                      необходимости в достижении этих целей;
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p>
                      истечения срока действия согласия на обработку персональных данных
                      или отзыва такого согласия;
                    </p>
                  </li>
                  <li data-list-text="-">
                    <p>прекращения деятельности организации.</p>
                  </li>
                </ul>
              </li>
              <li data-list-text="6.7.2.">
                <p>
                  В случае отсутствия возможности уничтожения персональных данных в
                  течение сроков, указанных в ч. 6 ст.21 Федерального закона №152-ФЗ «О
                  персональных данных», Оператор осуществляет блокирование таких данных
                  или обеспечивает их блокирование (если обработка осуществляется другим
                  лицом, действующим по поручению Оператора) и обеспечивает уничтожение
                  персональных данных в срок не более чем 6 (шесть) месяцев, если иной
                  срок не установлен федеральными законами.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="6.8.">
            <p>
              6.8 Обработка персональных данных осуществляется с соблюдением
              конфиденциальности. Оператор обеспечивает конфиденциальность персональных
              данных Субъекта персональных данных со своей стороны, со стороны своих
              работников, имеющих доступ к персональным данным физических лиц, а также
              обеспечивает использование персональных данных вышеуказанными лицами
              исключительно в целях, соответствующих закону, договору или иному
              соглашению, заключенному с Субъектом персональных данных.
            </p>
          </li>
        </ol>
      </li>
      <li data-list-text="7.">
        <h1>7. Меры по обеспечению защиты персональных данных</h1>
        <ol id="l17">
          <li data-list-text="7.1.">
            <p>
              7.1 В соответствии с частью 2 статьи 19 Федерального закона «О персональных
              данных» для обеспечения безопасности персональных данных при их обработке
              Оператор применяет организационные и технические меры защиты, необходимые
              для обеспечения соответствия установленным уровням защищенности персональных
              данных, в том числе:
            </p>
            <ul id="l18" className={styles.list}>
              <li data-list-text="-">
                <p>
                  назначение должностных лиц, ответственных за организацию обработки и
                  защиты персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  строгое ограничение круга лиц, имеющих доступ к персональным данным;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  ознакомление работников с требованиями федерального законодательства и
                  нормативных документов Оператора по обработке и защите персональных
                  данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  обеспечение учёта и хранения материальных носителей персональных данных
                  в условиях, обеспечивающих сохранность и недопущение
                  несанкционированного доступа к ним;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  регулярная оценка и определение угроз безопасности персональных данных
                  при их обработке в информационных системах персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  реализация разрешительной системы доступа пользователей к информационным
                  ресурсам, программно-аппаратным средствам обработки и защиты информации;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  регистрация и учёт действий пользователей информационных систем
                  персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  применение средств разграничения и контроля доступа к информации,
                  информационным ресурсам, информационным системам, коммуникационным
                  портам, устройствам ввода-вывода информации, съёмным машинным носителям
                  и внешним накопителям информации;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  реализация парольной защиты при осуществлении доступа пользователей к
                  информационной системе персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>применение средств межсетевого экранирования;</p>
              </li>
              <li data-list-text="-">
                <p>
                  осуществление антивирусного контроля, предотвращение внедрения
                  вредоносных программ (программ-вирусов) и программных закладок;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  применение средств криптографической защиты информации для обеспечения
                  безопасности персональных данных при передаче по открытым каналам связи
                  и хранении на машинных носителях информации;
                </p>
              </li>
              <li data-list-text="-">
                <p>обнаружение вторжений;</p>
              </li>
              <li data-list-text="-">
                <p>резервное копирование информации;</p>
              </li>
              <li data-list-text="-">
                <p>
                  обеспечение восстановления персональных данных, модифицированных или
                  уничтоженных вследствие несанкционированного доступа к ним;
                </p>
              </li>
              <li data-list-text="-">
                <p>централизованное управление системой защиты персональных данных;</p>
              </li>
              <li data-list-text="-">
                <p>
                  обучение работников, использующих средства защиты информации,
                  применяемые в информационных системах персональных данных, правилам
                  работы с ними;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  оценка эффективности принимаемых мер по обеспечению безопасности
                  персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  обнаружение фактов несанкционированного доступа к персональным данным и
                  принятие мер;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  контроль за принимаемыми мерами по обеспечению безопасности персональных
                  данных и уровня защищенности информационных систем персональных данных;
                </p>
              </li>
              <li data-list-text="-">
                <p>
                  размещение технических средств обработки персональных данных, в пределах
                  границ охраняемой территории;
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
      <li data-list-text="8.">
        <h1>
          8. Реагирование на обращения и запросы в области обработки персональных данных
        </h1>
        <ol id="l19">
          <li data-list-text="8.1.">
            <p>
              8.1 При обращении и запросе в письменном или электронном виде Субъекта
              персональных данных или его законного представителя, Оператор
              руководствуется требованиями ст. 14, 18 и 20 Федерального закона №152-ФЗ «О
              персональных данных».
            </p>
          </li>
          <li data-list-text="8.2.">
            <p>
              8.2 Заявление Субъекта персональных данных должно быть оформлено в
              произвольной форме с соблюдением требований Федерального закона «О
              персональных данных» и содержать:
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <ul id="l20" className={styles.list}>
      <li data-list-text="-">
        <p>
          серию, номер основного документа, удостоверяющего личность Субъекта персональных
          данных (его представителя), сведения о дате выдачи указанного документа и
          выдавшем его органе;
        </p>
      </li>
      <li data-list-text="-">
        <p>
          сведения, подтверждающие участие Субъекта персональных данных в отношениях с
          Оператором, либо сведения, иным образом подтверждающие факт обработки
          персональных данных Оператором;
        </p>
      </li>
      <li data-list-text="-">
        <p>подпись Субъекта персональных данных (его представителя).</p>
      </li>
    </ul>
    <p>
      8.3 В течение десяти рабочих дней с даты получения запроса Субъекта персональных
      данных или его законного представителя, Оператор предоставляет запрошенную
      информацию или мотивированный отказ.
    </p>
    <p>
      8.4 В соответствии с ч. 4 ст.20 Федерального закона №152-ФЗ «О персональных данных»
      при получении запроса от Уполномоченного органа по защите прав субъектов
      персональных данных Оператор предоставляет необходимую информацию в течение десяти
      рабочих дней с даты получения такого запроса.
    </p>
    <ol id="l21">
      <li data-list-text="9.">
        <h1>9. Заключительные положения</h1>
        <ol id="l22">
          <li data-list-text="9.1.">
            <p>
              9.1 Ответственность за нарушение требований законодательства Российской
              Федерации и нормативных документов Оператора в области персональных данных
              определяется в соответствии с законодательством Российской Федерации.
            </p>
          </li>
          <li data-list-text="9.2.">
            <p>
              9.2 Настоящая Политика вступает в силу с момента утверждения и действует
              бессрочно до принятия новой Политики.
            </p>
          </li>
          <li data-list-text="9.3.">
            <p>
              9.3 Все изменения и дополнения к настоящей Политике должны быть утверждены
              единоличным исполнительным органом Оператора.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <ol id="122">
      <li data-list-text="10.">
        <h1>10. Список партнёров</h1>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>Наименование</th>
              <th>ИНН</th>
              <th>ОГРН</th>
              <th>Адрес</th>
              <th>Лицензия</th>
            </tr>
          </thead>
          <tbody>
            {partnerList.map((el, index) => (
              <tr key={`${el.ogrn}_${index + 1}`}>
                <td>
                  <span>Наименование</span>
                  {el.name}
                </td>
                <td>
                  <span>ИНН</span>
                  {el.inn}
                </td>
                <td>
                  <span>ОГРН</span>
                  {el.ogrn}
                </td>
                <td>
                  <span>Адрес</span>
                  {el.address}
                </td>
                {window.innerWidth <= 992 ? (
                  !!el.license && (
                    <td>
                      <span>Лицензия</span>
                      {el.license}
                    </td>
                  )
                ) : (
                  <td>
                    <span>Лицензия</span>
                    {el.license}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </li>
    </ol>
  </div>
);

export default PoliticsDoc;
