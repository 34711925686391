import React, { CSSProperties, FC, useCallback, useEffect, useRef } from 'react';

import { DocumentParametersType } from '../Docs';
import OfferDoc from '../Docs/OfferDoc';
import PoliticsDoc from '../Docs/PoliticsDoc';
import ProcessingDoc from '../Docs/ProcessingDoc';

import styles from './Pdf.module.scss';

export type DocumentType = 'politics' | 'offer' | 'processing';
export interface PdfProps {
  theme: string;
  pdfFile: DocumentType | null;
  owner: string;
  origin: string;
  inn: string;
  ogrn: string;
  name: string;
  address: {
    factAddress: string;
    registrationAddress: string;
  };
  registrationNumber?: string;
  opened: boolean;
  closeHandler: () => void;
  headerStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  secondName?: string;
}

interface DocRouteType {
  file: DocumentType;
  element: React.ReactElement;
}

const router: DocRouteType[] = [
  { file: 'politics', element: <PoliticsDoc /> },
  { file: 'offer', element: <OfferDoc /> },
  { file: 'processing', element: <ProcessingDoc /> },
];

interface DocumentProps extends DocumentParametersType {
  file: DocumentType;
  secondName?: string;
  name: string;
}

const MyDocument: FC<DocumentProps> = ({ file, ...props }) => {
  const elem = router.find(el => el.file === file);

  if (!elem) return null;

  const { element } = elem;

  return React.cloneElement(element, { ...props });
};

export const Pdf: FC<PdfProps> = ({
  pdfFile,
  closeHandler,
  opened,
  owner,
  address,
  registrationNumber,
  inn,
  ogrn,
  origin,
  containerStyle,
  buttonStyle,
  headerStyle,
  secondName,
  name,
  theme,
}) => {
  const pdfRef = useRef<HTMLDivElement>(null);

  const mail = `support@${origin
    .replace(/http+s?:\/\//i, '')
    .replace(/(:[0-9]{4})/i, '')}`;

  const closePdf = useCallback(() => {
    document.documentElement.style.removeProperty('overflow-y');
    closeHandler();
  }, [closeHandler]);

  useEffect(() => {
    if (!pdfFile || !opened) return;

    document.documentElement.style.overflowY = 'hidden';
  }, [pdfFile, opened]);

  return (
    pdfFile &&
    opened && (
      <div
        className={`${styles.pdfContainer} ${styles[`pdfContainer__${theme}`]}`}
        style={containerStyle}
        ref={pdfRef}
      >
        <div
          className={`${styles.pdfContainer__header} ${
            styles[`pdfContainer__${theme}__header`]
          }`}
          style={headerStyle}
        >
          <div className={`${styles.title} ${styles[`title__${theme}`]}`}>
            Вы находитесь в режиме{' '}
            <span className={`${styles.title__mode} ${styles[`title__${theme}__mode`]}`}>
              просмотра документов
            </span>
            . Чтобы вернуться на страницу нажмите кнопку{' '}
            <span
              className={`${styles.title__close} ${styles[`title__${theme}__close`]}`}
              onClick={closePdf}
              aria-hidden
            >{`"Закрыть"`}</span>
          </div>
        </div>

        <div
          className={`${styles.pdfContainer__document} ${
            styles[`pdfContainer__${theme}__document`]
          }`}
        >
          <MyDocument
            file={pdfFile}
            owner={owner}
            ogrn={ogrn}
            inn={inn}
            origin={origin}
            address={address.factAddress}
            registrationNumber={registrationNumber}
            name={name}
            secondName={secondName}
            mail={mail}
          />
        </div>

        <div
          className={`${styles.pdfContainer__btn} ${
            styles[`pdfContainer__${theme}__btn`]
          }`}
          style={buttonStyle}
        >
          <button
            className={`${styles.closeBtn} ${styles[`closeBtn__${theme}`]}`}
            onClick={closePdf}
            type="button"
          >
            Закрыть
          </button>
        </div>
      </div>
    )
  );
};
