type PartnerType = {
  name: string;
  inn: string;
  ogrn: string;
  address: string;
  license: string;
};

export type DocumentParametersType = {
  origin?: string;
  owner?: string;
  address?: string;
  registrationNumber?: string;
  inn?: string;
  ogrn?: string;
  name?: string;
  mail?: string;
};

const partnerList: PartnerType[] = [
  {
    name: 'Публичное акционерное общество "Уральский банк реконструкции и развития"',
    inn: '6608008004',
    ogrn: '1026600000350',
    address: '620063, Свердловская Область, г. Екатеринбург, ул. 8 Марта, д. 51',
    license: 'Генеральная лицензия Банка России № 429',
  },
  {
    name: 'Публичное акционерное общество "МТС Банк"',
    inn: '7702045051',
    ogrn: '1027739053704',
    address: '115432, г. Москва, пр-кт Андропова, д. 18, к. 1',
    license: 'Генеральная лицензия Банка России № 2268',
  },
  {
    name: 'Публичное акционерное общество "Совкомбанк" ',
    inn: '4401116480',
    ogrn: '1144400000425',
    address: '156000, Костромская Область, г. Кострома, пр-кт Текстильщиков, д. 46',
    license: 'Генеральная лицензия Банка России № 963',
  },
  {
    name: 'Общество с ограниченной ответственностью "Т-Партнерс"',
    inn: '7743369908',
    ogrn: '1217700548042',
    address: '125212, г.Москва, Головинское шоссе, д. 5, корп.1, этаж/пом. 5/5017',
    license: '',
  },
  {
    name: 'Акционерное общество "ТБАНК" ',
    inn: '7710140679',
    ogrn: '1027739642281',
    address: '127287, г. Москва, ул. Хуторская 2-я, д. 38а, стр. 26',
    license: 'Генеральная лицензия Банка России № 2673',
  },
  {
    name: 'Акционерное общество "Альфа-Банк"',
    inn: '7728168971',
    ogrn: '1027700067328',
    address: '107078, г. Москва, ул. Каланчевская, д. 27',
    license: 'Генеральная лицензия Банка России № 1326',
  },
  {
    name: 'Акционерное общество "Свой Банк"',
    inn: '6453031840',
    ogrn: '1026400001870',
    address:
      '121096, г. Москва, вн. тер. г. Муниципальный округ филевский парк, ул. Василисы Кожиной, д. 1, офис №804.8',
    license: 'Генеральная лицензия Банка России № 3223',
  },
  {
    name: 'Публичное акционерное общество "Банк Уралсиб" ',
    inn: '0274062111',
    ogrn: '1020280000190',
    address: '119048, г. Москва, ул. Ефремова, д. 8',
    license: 'Генеральная лицензия Банка России № 2275',
  },
  {
    name: 'Акционерное общество "Локо-Банк"',
    inn: '7750003943',
    ogrn: '1057711014195',
    address: '125167, г. Москва, Ленинградский проспект, дом 39, строение 80',
    license: 'Генеральная лицензия Банка России № 2707',
  },
  {
    name: 'Акционерное общество "ГПБ"',
    inn: '7744001497',
    ogrn: '1027700167110',
    address: '117420, г. Москва, ул. Наметкина, д. 16, корпус 1',
    license: 'Генеральная лицензия Банка России № 354',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрофинансовая компания "Юпитер 6"',
    inn: '7840460408',
    ogrn: '1117847472973',
    address: '199397, Санкт-петербург Город, ул. Кораблестроителей, д. 32, к. 1 литер а',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций  2-11-01-78-000979',
  },
  {
    name: 'Обществом с ограниченной ответственностью Микрофинансовой компанией "Быстроденьги"',
    inn: '7325081622',
    ogrn: '1087325005899',
    address: '121087, г. Москва, ул. Барклая, д. 6, стр. 5',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 2-11-05-73-000002',
  },
  {
    name: 'Обществом с ограниченной ответственностью Микрофинансовая компания "Лайм-Займ"',
    inn: '7724889891',
    ogrn: '1137746831606',
    address: '630102, Новосибирская Область, г. Новосибирск, ул. Кирова, д. 48',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 65-13-030-45-004102',
  },
  {
    name: 'Обществом с ограниченной ответственностью Микрофинансовой компанией "ЭйрЛоанс"',
    inn: '7716748537',
    ogrn: '1137746543296',
    address: '123112, г. Москва, наб Пресненская, д. 8, стр. 1',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 65-13-030-45-003744',
  },
  {
    name: 'Обществом с ограниченной ответственностью Микрофинансовой компанией "Мани Мен"',
    inn: '7704784072',
    ogrn: '1117746442670',
    address: '121096, г. Москва, ул. Василисы Кожиной, д. 1',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций  2-11-01-77-000478',
  },
  {
    name: 'Общество с ограниченной ответственностью микрофинансовая организация "Займ Экспресс"                                                                                                                                                                                                                ',
    inn: '7727696672',
    ogrn: '1097746489741',
    address: '117042, г. Москва, ул. Южнобутовская, д. 25',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 2-11-01-77-000538',
  },
  {
    name: 'Обществом с ограниченной ответственностью "Займиго МФК"',
    inn: '5260355389',
    ogrn: '1135260005363',
    address: '603093, Нижегородская Область, г. Нижний Новгород, ул. Ковровская, д. 21а',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 65-13-033-22-004222',
  },
  {
    name: 'ИП Якушина Оксана Николаевна',
    inn: '290203865450',
    ogrn: '317290100003057',
    address: '164500, Архангельская область, г. Северодвинск, ул. Советская, д. 3, кв. 9',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций ',
  },
  {
    name: 'Общество с ограниченной ответственностью "Микрокредитная компания Скорость финанс"',
    inn: '3664223480',
    ogrn: '1163668109428',
    address: '344034, Ростовская Область, г Ростов-на-дону, ул Портовая, д. 193',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 17-030-20-008232',
  },
  {
    name: 'Акционерное общество микрофинансовая компания "Займер"',
    inn: '5406836941',
    ogrn: '1235400049356',
    address: '630099, Новосибирская Область, г. Новосибирск, мгстр. Октябрьская, д. 3',
    license:
      'Регистрационный номер записи в государственном реестре микрофинансовых организаций 65-13-035-32-004088',
  },
  {
    name: 'Общество с ограниченной ответственностью "Сравни.ру"',
    inn: '7710718303',
    ogrn: '1087746642774',
    address: '109544, г. Москва, бульвар Энтузиастов, дом 2, 26 этаж',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью "Банки.ру"',
    inn: '7723527345',
    ogrn: '1047796964522',
    address:
      '117638, г. Москва, вн.тер.г. Муниципальный Округ Зюзино, ул Одесская, д. 2, этаж 19 ',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью "Фин Промоут Системс"',
    inn: '6671285919',
    ogrn: '1236600060290',
    address:
      '620146, Свердловская область, г.о. Город Екатеринбург, г Екатеринбург, ул Волгоградская, д. 18А, оф. 192',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью "Юником24"',
    inn: '9705131016',
    ogrn: '1197746250272',
    address:
      '125167, г. Москва, вн.тер.г. Муниципальный Округ Хорошевский, пр-кт Ленинградский, д. 37, этаж 12, помещ./ком. 50/12-01',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью "Сипиэй Сервис"',
    inn: '9704072625',
    ogrn: '1217700287331',
    address:
      '121099, г. Москва, вн.тер.г. Муниципальный Округ Арбат, проезд Шломина, д. 6, помещ. V, ком. 1 оф 39 ',
    license: '',
  },
  {
    name: 'Акционерный коммерческий банк «АК БАРС» ',
    inn: '1653001805',
    ogrn: '1021600000124',
    address: '420066, Республика Татарстан, г. Казань, ул. Декабристов, 1',
    license: 'Регистрационный номер 2590',
  },
  {
    name: 'Микрофинансовая компания «Центр Финансовой Поддержки» (публичное акционерное общество)',
    inn: '7727480641',
    ogrn: '1217700621709',
    address:
      '117218,г. Москва, вн.тер.г. муниципальный округ Котловка, ул Кржижановского, д. 29, к. 5, помещ. I, ком. 10',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-11-01-77-000840',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрофинансовая компания «Займ Онлайн»',
    inn: '7703769314',
    ogrn: '1127746428171',
    address: '127083, город Москва, ул 8 Марта, д. 1 стр. 12, эт/пом/ком 10/LVI/2-7',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-12-01-77-001838',
  },
  {
    name: 'Общество с ограниченной ответственностью «Юстива»',
    inn: '7813295787',
    ogrn: '1177847401500',
    address:
      '420500, Республика Татарстан, Верхнеуслонский р-н, г Иннополис, Университетская ул, д. 7, офис 503 ',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-11-01-77-000471',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрофинансовая компания «КарМани»',
    inn: '7730634468',
    ogrn: '1107746915781',
    address: '119019, г. Москва, ул. Воздвиженка д. 9, стр. 2, пом. 1',
    license: '',
  },
  {
    name: 'Индивидуальный предприниматель Гаврилов Владислав Олегович',
    inn: '026808748400',
    ogrn: '324028000199617',
    address: '453126, Россия, г. Стерлитамак, ул. Советская, д. 98, ком. 7',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «А ДЕНЬГИ»',
    inn: '7708400979',
    ogrn: '1217700636944',
    address:
      '107078, г.Москва, вн.тер.г. Муниципальный округ Красносельский, ул. Маши Порываевой, д. 7, стр. а, этаж/помещ./ком. 8/5-6Б/805-806',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 22-030-45-009821',
  },
  {
    name: 'Общество с ограниченной ответственностью микрокредитная компания «Русинтерфинанс»',
    inn: '5408292849',
    ogrn: '1125476023298',
    address: '630055, Новосибирская обл, г Новосибирск, ул Гнесиных, д 10/1, оф 202',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-12-07-54-001243',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрофинансовая компания «Джой Мани»',
    inn: '5407496776',
    ogrn: '1145476064711',
    address:
      '630099, Новосибирская область, город Новосибирск, Советская ул., д. 12, этаж 4',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 65-14-035-50-005450',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Триумвират»',
    inn: '7743384078',
    ogrn: '1227700365815',
    address:
      '117342, г. Москва, муниципальный округ Коньково, ул. Бутлерова, д. 17, пом. 235/3',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 22-030-45-009920',
  },
  {
    name: 'Общество с ограниченной ответственностью «Микрокредитная компания Денежная крепость»',
    inn: '9710052313',
    ogrn: '1187746276541',
    address: '101000, Москва, пер. Старосадский, д. 6/12 СТР. 1, эт 1/пом ii/ком 2',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 18-030-45-008815',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Финансовый супермаркет»',
    inn: '5404017915',
    ogrn: '1155476090967',
    address:
      '630102, Новосибирская область, г. Новосибирск, ул. Шевченко, д.15/1, этаж 3, офис 2',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 00-15-035-50-007231',
  },
  {
    name: 'Банк ЗЕНИТ (публичное акционерное общество)',
    inn: '7729405872',
    ogrn: '1027739056927',
    address: '117638, г. Москва, Одесская ул., д. 2',
    license: 'Регистрационный номер 3255',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «СМСФИНАНС»',
    inn: '7724351447',
    ogrn: '1167746117483',
    address:
      '115093, РФ, Москва, Люсиновская, 36, стр.1, этаж 3, помещение №1, комната №4',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-12-01-77-002022',
  },
  {
    name: 'Общество с ограниченной ответственностью микрофинансовая компания «МигКредит»',
    inn: '7715825027',
    ogrn: '1107746671207',
    address: '127018, г. Москва, ул. Сущевский Вал, д. 5, стр. 3',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-11-01-77-000037',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Срочноденьги»',
    inn: '5260271530',
    ogrn: '1105260001857',
    address: '603022, Нижегородская обл., г.Н. Новгород, ул. Тимирязева, 15/2, пом. 403',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-11-05-52-000304',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «ПрофиМани»',
    inn: '2722103753',
    ogrn: '1112722004581',
    address:
      '630091, Новосибирская область, г. Новосибирск, ул. Державина, д. 28, эт. 3, оф. 302',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-11-08-27-000326',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Веритас»',
    inn: '7706780186',
    ogrn: '1127746672130',
    address:
      '115114, Город Москва, Улица Летниковская, Дом 10, Строение 4, Пом. I, Ком 15',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 65-13-030-45-003161',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «МАКРО»',
    inn: '7722355735',
    ogrn: '1167746181790',
    address:
      '115114, Город Москва, Улица Летниковская, Дом 10, Строение 4, Пом. I, Ком 15',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 00-16-030-45-007582',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Занимательные финансы»',
    inn: '9717113526',
    ogrn: '1227700228766',
    address: '129515, г. Москва, ул. Академика Королева, д. 5, подв. 0, офис 11С',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 22-030-45-009908',
  },
  {
    name: 'Общество с ограниченной ответственностью «ДЕНЬГИ НА ДОМ»',
    inn: '7814573765',
    ogrn: '1137847199170',
    address: ' 197372, г. Санкт-Петербург, Яхтенная улица, 42, 157',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «Кредиттер»',
    inn: '7702463482',
    ogrn: '1197746100530',
    address: '105064, г. Москва, пер. Нижний Сусальный,  д. 5, стр. 17',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 19-030-45-009373',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «АЛИЗАЙМ»',
    inn: '7743224902',
    ogrn: '1177746950171',
    address: '125212, Москва, Ленинградское ш., 25, корп. 2, помещение VII, комната 5',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 17-030-45-008650',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «КапиталЪ-НТ»',
    inn: '6623106990',
    ogrn: '1146623008707',
    address: '443095, Самарская область, Самара, ул. Стара-Загора, д. 202А, офис 301',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 00-16-034-65-007766',
  },
  {
    name: 'КРЕДИТНЫЙ ПОТРЕБИТЕЛЬСКИЙ КООПЕРАТИВ "ИЛМА-КРЕДИТ"',
    inn: '1001241307',
    ogrn: '1101001012804',
    address:
      '606800, обл Нижегородская, р-н Уренский, г Урень, ул Ленина, д. 75, помещ. 2',
    license: '',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «495 Кредит»',
    inn: '9704063067',
    ogrn: '1217700207427',
    address:
      '119435, г. Москва, вн. тер. г. Муниципальный округ Хамовники, пер. Большой Саввинский, д. 12, стр. 8, эт. 2, пом./комн./часть I/10-15/1,8',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 21-030-45-009690',
  },
  {
    name: 'Общество с ограниченной ответственностью микрофинансовая компания «ВЭББАНКИР»',
    inn: '7733812126',
    ogrn: '1127746630846',
    address: '125627, город Москва, Соколово-Мещерская ул, д. 29, офис 308',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 2-12-01-77-002077',
  },
  {
    name: 'Общество с ограниченной ответственностью Микрокредитная компания «ФИНТЕРРА»',
    inn: '4205219217',
    ogrn: '1114205007443',
    address:
      '630099, Новосибирская область, город Новосибирск, Октябрьская мгстр., д. 3, офис 903',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 65-13-035-32-002603',
  },
  {
    name: 'Общество с ограниченной ответственностью «Микрокредитная компания «Деньгимигом»',
    inn: '1650244278',
    ogrn: '1121650010943',
    address:
      '423822, Республика Татарстан, г. Набережные Челны, пр-кт Набережночелнинский, д. 84',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 65-13-033-92-002815',
  },
  {
    name: 'Общество с ограниченной ответственностью "Микрокредитная компания "ПРОФИРЕАЛ"',
    inn: '7838492459',
    ogrn: '1137847255765',
    address:
      '197136, г. Санкт-Петербург, Улица Всеволода Вишневского, дом 12, лит.А, пом.2Н, офис 401',
    license: 'Учетный номер (номер лицензии, номер записи в реестре) 65-13-031-40-004444',
  },
];

export { partnerList };
